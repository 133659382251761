import { useLocales } from '@/Locales';
import { httpPatientRepeatRecord, httpPatientSubmitGptResult } from '@/services/imageProcess';
import { message } from '@sinohealth/butterfly-ui-components';
import { useEffect, useState } from 'react';
import {
  formatField,
  imgField,
  jsonField,
  specialField,
} from '../components/WaitAddData/components/CompareModel';

/**
 * @description: 提交患者病历智能识别结果至病历
 * @author: KaifengLi
 * @version: v2.27.0
 * @Date: 2024-11-22 11:28:14
 */
export const useSubmitGptResult = () => {
  const [submitParams, setSubmitParams] = useState<any>();
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const { t } = useLocales();

  const httpPatientSubmitGptResultReq = async () => {
    try {
      setSubmitSuccess(false);
      setSubmitLoading(true);
      const res = await httpPatientSubmitGptResult(submitParams);
      setSubmitSuccess(true);
      message.success(t('已添加至病历'));
    } catch (error) {
      console.log(error);
    } finally {
      setSubmitLoading(false);
    }
  };

  useEffect(() => {
    // console.log(submitParams);
    submitParams && httpPatientSubmitGptResultReq();
  }, [submitParams]);

  return { setSubmitParams, submitLoading, submitSuccess };
};

/**
 * @description: 获取与患者病历智能识别结果重复的记录
 * @author: KaifengLi
 * @version: v2.27.0
 * @Date: 2024-11-22 11:31:56
 */
export const useRepeatRecord = () => {
  const [repeatParams, setRepeatParams] = useState<any>();
  const [repeatLoading, setRepeatLoading] = useState(false);
  const [loadRepeatSucc, setLoadRepeatSucc] = useState(false);
  const [compareOldDetail, setCompareOldDetail] = useState<any>();
  const [otherDetail, setOtherDetail] = useState<any>({});

  const httpPatientRepeatRecordReq = async () => {
    try {
      setRepeatLoading(true);
      const res: any = await httpPatientRepeatRecord(repeatParams);
      const { jsonValue, ...etc } = res || {};
      if (res?.jsonValue) {
        // console.log(res?.jsonValue);
        setOtherDetail(etc);
        setCompareOldDetail(JSON.parse(jsonValue));
      }
      setLoadRepeatSucc(true);
    } catch (error) {
      console.log(error);
    } finally {
      setRepeatLoading(false);
      setRepeatParams(undefined);
      setLoadRepeatSucc(false);
    }
  };

  useEffect(() => {
    repeatParams && httpPatientRepeatRecordReq();
  }, [repeatParams]);

  return {
    setRepeatParams,
    loadRepeatSucc,
    setLoadRepeatSucc,
    repeatLoading,
    otherDetail,
    compareOldDetail,
  };
};

export const useCalcParams = (mergeType: number, old: any, newD: any, compareType?: string) => {
  let params: any = {};
  if (mergeType === 1) {
    // 合并同一日期，且内容进行新增
    if (compareType !== 'pathologyReports') {
      params = { ...old };
    } else {
      // eslint-disable-next-line no-param-reassign
      delete old.diagnosisDescription;
      params = { ...old };
    }
    // console.log({ old, compareNewDetail });
    Object.keys(newD).forEach((key) => {
      if (
        !formatField.includes(key) &&
        !specialField.includes(key) &&
        !jsonField.includes(key) &&
        !imgField.includes(key)
      ) {
        params[key] = `${!old[key] ? '' : old[key]}${!newD[key] ? '' : newD[key]}`;
        // 影像特殊处理
        if (compareType === 'pathologyReports') {
          params.diagnosisDescription = {
            summary: `${!old.summary ? '' : old.summary}${!newD.summary ? '' : newD.summary}`,
            immunohistochemistry: `${!old.immunohistochemistry ? '' : old.immunohistochemistry}${
              !newD.immunohistochemistry ? '' : newD.immunohistochemistry
            }`,
            tumorStaging: newD.tumorStaging,
          };
        }
        if (compareType === 'inspectionReports') {
          // 对type字段进行去重处理
          // 处理字符串类型的检查项，去除重复项
          params.type = Array.from(
            new Set(
              (old?.type || '')
                .split('、')
                .concat(newD?.type?.split('、') || [])
                .filter(Boolean),
            ),
          ).join('、');
          params.zhInspectionItems = [].concat(
            old?.zhInspectionItems || [],
            newD?.zhInspectionItems || [],
          );
        }
      }
      // 特殊字段按新数据
      if (formatField.includes(key) || specialField.includes(key)) {
        params[key] = `${newD[key] || ''}`;
      }
      // JSON字符处理
      if (jsonField.includes(key)) {
        params[key] = `${old[key] ? `${old[key]}\n` : ''}${newD[key] || ''}`;
      }
      // 图片处理
      if (imgField.includes(key)) {
        params[key] = `${old[key] ? `${old[key]},` : ''}${newD[key] || ''}`;
      }
    });
  }
  if (mergeType === 2) {
    // 合并同一日期，但内容进行覆盖
    Object.keys(newD).forEach((key) => {
      params[key] = newD[key] === null ? '' : newD[key];
    });
    // 影像特殊处理
    if (compareType === 'pathologyReports') {
      params.diagnosisDescription = {
        summary: newD.summary,
        immunohistochemistry: newD.immunohistochemistry,
        tumorStaging: newD.tumorStaging,
      };
    }
  }
  if (mergeType === 3) {
    // 按新日期内容添加
    params = { ...newD };
    // 影像特殊处理
    if (compareType === 'pathologyReports') {
      params.diagnosisDescription = {
        summary: newD.summary,
        immunohistochemistry: newD.immunohistochemistry,
        tumorStaging: newD.tumorStaging,
      };
    }
  }
  if (compareType === 'pathologyReports') {
    params.diagnosisDescription = JSON.stringify(params.diagnosisDescription);
  }
  // props.onOk(values);
  // console.log(params);

  return params;
};

/*
 * @Description: 讲稿演示-主要内容-患者信息
 * @Author: likaifeng
 * @Date: 2023-11-30 16:12:41
 * @LastEditTime: 2025-03-24 14:02:19
 * @LastEditors: 李开锋
 */
import { FormItem } from '@/components/BaseForm';
import TumorStageSelect from '@/components/TumorStageSelect';
import { useLocales } from '@/Locales';
import { useCombinateData, usePatientDetail } from '@/pages/speechLecture/hook/makeSpeech';
import { updatePatientInfo } from '@/services/patient';
import { speechFilterEditAtom, speechUpdateTimeAtom } from '@/store/speech';
import { maskMiddleCharacters, transformTumorStage } from '@/utils';
import { gender } from '@/utils/constant';
import { Form, Input, InputNumber, Popover, Select } from '@sinohealth/butterfly-ui-components';
import classNames from 'classnames';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { ORIGIN_TYPE } from '../HocForm';
import SpeechCard from '../SpeechCard';
import styles from './index.less';

const { TextArea } = Input;

/**
 * @description: 讲稿演示-主要内容-患者信息
 * @author: likaifeng
 * @return {*}
 * @Date: 2023-12-04 14:27:51
 */
const SpeechPatient: React.FC = () => {
  const [form] = Form.useForm();
  const speechFilterEditFlag = useRecoilValue(speechFilterEditAtom);
  const speechUpdateTime = useRecoilValue(speechUpdateTimeAtom);
  const { t } = useLocales();

  const [firstRender, setFirstRender] = useState(true);
  const { patientDetail, setReload } = usePatientDetail();
  useCombinateData(ORIGIN_TYPE.baseInfo, patientDetail);

  const values = Form.useWatch([], form);

  useEffect(() => {
    try {
      form?.validateFields().then(
        () => {
          if (speechFilterEditFlag && !firstRender) {
            throttledOnFieldsChange({ ...patientDetail, ...values });
          }
        },
        (err) => {
          // console.log(err);
        },
      );
    } catch (err) {
      console.log(err);
    }
  }, [form, values]);

  // 使用 throttle 函数限制 onFieldsChange 的执行频率
  const throttledOnFieldsChange = useCallback(
    _.debounce(
      (val) => {
        // console.log('Fields changed', val);
        updatePatientInfo({
          ...val,
        }).then(() => {
          console.log('更新成功');
          setReload(true);
        });
      },
      speechUpdateTime,
      { leading: false },
    ),
    [],
  ); // 3000 milliseconds = 3

  useEffect(() => {
    if (speechFilterEditFlag) {
      form.setFieldsValue(patientDetail);
      const timer = setTimeout(() => {
        setFirstRender(false);
        clearTimeout(timer);
      }, 3000);
    } else {
      setFirstRender(true);
    }
  }, [speechFilterEditFlag]);

  return (
    <SpeechCard id="baseInfo" title="患者信息">
      <div className={styles['speech-patient']}>
        <Form form={form} name="patient" initialValues={patientDetail || {}} autoComplete="off">
          <div className={styles.container}>
            <ul className={styles.patient}>
              <Form.Item className={styles['speech-form-item']} name="name">
                <li className={styles['patient-item']}>
                  <span className={styles['item-label']}>{t('姓名')}</span>
                  {!speechFilterEditFlag ? (
                    <span className={styles['item-name']}>
                      {maskMiddleCharacters(patientDetail?.name) || t('未填写')}
                    </span>
                  ) : (
                    <Form.Item name="name" noStyle>
                      <Input placeholder="请输入患者名称" />
                    </Form.Item>
                  )}
                </li>
              </Form.Item>
              <Form.Item className={styles['speech-form-item']} name="gender">
                <li className={styles['patient-item']}>
                  <span className={styles['item-label']}>{t('性别')}</span>
                  {!speechFilterEditFlag ? (
                    <span className={styles['item-name']}>
                      {t(gender[patientDetail?.gender]) || t('未填写')}
                    </span>
                  ) : (
                    <Form.Item name="gender" noStyle>
                      <Select
                        placeholder="请选择"
                        options={[
                          { value: '0', label: '男' },
                          { value: '1', label: '女' },
                          { value: '2', label: '保密' },
                        ]}
                      />
                    </Form.Item>
                  )}
                </li>
              </Form.Item>
              <Form.Item className={styles['speech-form-item']} name="age">
                <li className={styles['patient-item']}>
                  <span className={styles['item-label']}>{t('年龄')}</span>
                  {!speechFilterEditFlag ? (
                    <span className={styles['item-name']}>{patientDetail?.age || t('未填写')}</span>
                  ) : (
                    <Form.Item name="age" noStyle>
                      <InputNumber placeholder="请输入患者年龄" style={{ width: '100%' }} />
                    </Form.Item>
                  )}
                </li>
              </Form.Item>
              <Form.Item className={styles['speech-form-item']} name="hospitalName">
                <li className={styles['patient-item']}>
                  <span className={styles['item-label']}>{t('医院')}</span>
                  <Popover content={patientDetail?.hospitalName || t('未填写')}>
                    {!speechFilterEditFlag ? (
                      <span className={styles['item-name']}>
                        {patientDetail?.hospitalName || t('未填写')}
                      </span>
                    ) : (
                      <Form.Item name="hospitalName" noStyle>
                        <Input placeholder="请输入医院名称" />
                      </Form.Item>
                    )}
                  </Popover>
                </li>
              </Form.Item>
              <FormItem
                className={styles['speech-form-item']}
                name="tumorStage"
                span={10}
                transformText={transformTumorStage}
              >
                <li className={styles['patient-item']}>
                  <span className={styles['item-label']}>{t('肿瘤分期')}</span>
                  {!speechFilterEditFlag ? (
                    <span className={styles['item-name']}>
                      {transformTumorStage(patientDetail?.tumorStage) || t('未填写')}
                    </span>
                  ) : (
                    <FormItem name="tumorStage" transformText={transformTumorStage} noStyle>
                      <TumorStageSelect style={{ width: 260 }} />
                    </FormItem>
                  )}
                </li>
              </FormItem>
            </ul>
            <Form.Item className={styles['speech-form-item']} name="latestDiagnosis">
              <div
                className={classNames(styles.footer, speechFilterEditFlag && styles['form-footer'])}
              >
                <span className={styles['footer-label']}>{t('最新诊断')}</span>
                <Popover content={patientDetail?.latestDiagnosis || t('未填写')}>
                  {!speechFilterEditFlag ? (
                    <span className={styles['footer-desc']}>
                      {patientDetail?.latestDiagnosis || t('未填写')}
                    </span>
                  ) : (
                    <Form.Item name="latestDiagnosis" noStyle>
                      <TextArea
                        placeholder="请输入最新诊断"
                        autoSize={{ minRows: 4, maxRows: 4 }}
                      />
                    </Form.Item>
                  )}
                </Popover>
              </div>
            </Form.Item>
          </div>
        </Form>
      </div>
    </SpeechCard>
  );
};

export default SpeechPatient;

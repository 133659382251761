import { dateConfig } from '@/pages/patient/patientOcrImage/components/WaitAddData/components/RecordItemBox';
import {
  addPatientMedicalResult,
  deletePatientMedicalOcrItem,
  getPatientMedicalOcrResult,
  getPatientMedicalOcrTask,
} from '@/services/patient';
import { message } from '@sinohealth/butterfly-ui-components';
import moment from 'moment';
import { useEffect, useState } from 'react';

// 获取患者病历智能识别任务
const usePatientOcrResult = (patientId: string) => {
  const [ocrResult, setOcrResult] = useState<any>({});
  const [taskTime, setTaskTime] = useState<any>();
  const [taskId, setTaskId] = useState<any>();
  // 控制是否需要加载图片分类接口
  const [canLoadImageClassify, setCanLoadImageClassify] = useState(false);

  useEffect(() => {
    if (patientId) {
      getPatientOcrTask();
    }
  }, []);

  const getPatientOcrTask = async () => {
    setOcrResult({});
    taskTime && clearTimeout(taskTime);
    const id = await getPatientMedicalOcrTask({
      patientId,
      taskType: 'mergeMedicalIntellectAndPatientHistoryOcr',
    });
    if (id) {
      setTaskId(id);
      getPatientOcrResult(id);
    } else {
      setTaskId(undefined);
      console.log('获取智能识别任务失败');
      setCanLoadImageClassify(true);
    }
  };

  const sortDataByDate = (list: any[], timeKey: string) => {
    if (!Array.isArray(list)) return list;
    // 按日期由远到近排序，无日期放最后
    return list.sort((item, nextItem) => {
      const date = item[timeKey];
      const nextDate = nextItem[timeKey];
      if (!date) return 1;
      if (!nextDate) return -1;
      return moment(date).diff(moment(nextDate)) * -1;
    });
  };
  const getPatientOcrResult = async (id: string) => {
    getPatientMedicalOcrResult({
      taskId: id,
    }).then((res) => {
      const sortedData: any = {};
      if (res?.medical) {
        Object.keys(dateConfig).forEach((key) => {
          sortedData[key] = sortDataByDate(res?.medical[key], dateConfig[key]?.key);
        });
      }
      // console.log({ sortedData });
      setOcrResult({
        ...res,
        ...sortedData,
      });
      if (res.status === 'userConfirm') {
        getPatientOcrTask();
        // resetPatientMedicalOcr({
        //   taskId: id,
        // })
        //   .then(() => {
        //   })
        //   .catch(() => {});
      }
      if (['historyParsing', 'medicalParsing'].includes(res.status)) {
        const time = setTimeout(() => {
          getPatientOcrResult(id);
        }, 5000);
        setTaskTime(time);
      }
      setCanLoadImageClassify(true);
    });
  };
  // 新增某个tab类型的数据
  const addDataItem = async (type: string) => {
    const bizId = await addPatientMedicalResult({
      taskId,
      reportType: type.substring(0, type.length - 1),
    });
    const nowData = ocrResult[type] ? [...ocrResult[type]] : [];
    nowData.push({ bizId });
    setOcrResult({
      ...ocrResult,
      [type]: nowData,
    });
    return bizId;
  };
  // 删除某个tab类型的数据
  const deleteDateItem = async (bizId: string, type: string) => {
    // console.log(ocrResult[type]);
    await deletePatientMedicalOcrItem(bizId);
    message.success('已删除！');
    const nowData = ocrResult[type] ? [...ocrResult[type]] : [];
    // console.log({ nowData: nowData.filter((item) => item.bizId !== bizId) });
    setOcrResult({
      ...ocrResult,
      [type]: nowData.filter((item) => item.bizId !== bizId),
    });
    console.log({ ocrResult });
    getPatientOcrTask();
  };

  useEffect(() => {
    return () => {
      taskTime && clearTimeout(taskTime);
    };
  }, [taskTime]);

  return [ocrResult, getPatientOcrTask, addDataItem, deleteDateItem, taskId, canLoadImageClassify];
};

export default usePatientOcrResult;

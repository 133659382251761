/*
 * @Description: 讲稿演示-主要内容-检验报告
 * @Author: likaifeng
 * @Date: 2023-11-30 17:35:52
 * @LastEditTime: 2025-03-21 16:52:32
 * @LastEditors: 李开锋
 */
import useDict from '@/hooks/dict/useDict';
import { useLocales } from '@/Locales';
import InspectionItemsTable, {
  StatusColor,
  StatusTips,
} from '@/pages/patient/detail/components/InspectionReportsTab/components/InspectionItemsTable';
import { speechFilterMakeDataAtom } from '@/store/lecture';
import React from 'react';
import { useRecoilValue } from 'recoil';
import SpeechCard from '../SpeechCard';
import SpeechField from '../SpeechField';
import SpeechStep from '../SpeechStep';
import SpeechStepCard from '../SpeechStepCard';
import styles from './index.less';

/**
 * @description: 讲稿演示-主要内容-检验报告
 * @author: likaifeng
 * @return {*}
 * @Date: 2023-12-04 14:27:00
 */
const SpeechInspect: React.FC = () => {
  const tumorMarker = useDict('tumorMarker');
  const speechFilterMakeData = useRecoilValue(speechFilterMakeDataAtom);
  const { t } = useLocales();

  /**
   * @description: 计算表格的列
   * @author: likaifeng
   * @param {any} data
   * @return {*}
   * @Date: 2023-12-01 10:17:53
   */
  const clacTableColumns = (data: any): any => {
    const arr: any = [];

    if (!data) return arr;

    // if (data?.type) {
    //   arr.push({
    //     title: t('检验项目'),
    //     dataIndex: 'type',
    //     key: 'type',
    //     align: 'center',
    //     render(text: string, item: any): JSX.Element {
    //       return <span>{t(inspctionType[Number(text)])}</span>;
    //     },
    //   });
    // }
    arr.push({
      title: t('指标名称'),
      dataIndex: 'itemName',
      key: 'itemName',
      align: 'center',
      render(text: string, record: any) {
        return <span style={{ color: StatusColor?.[record.status] }}>{text}</span>;
      },
    });
    arr.push({
      title: t('结果'),
      dataIndex: 'resultValueItem',
      key: 'resultValueItem',
      align: 'center',
      render(text: string, record: any) {
        return <span style={{ color: StatusColor?.[record.status] }}>{text}</span>;
      },
    });
    arr.push({
      title: t('提示'),
      dataIndex: 'resultValueItem',
      key: 'resultValueItem',
      align: 'center',
      render(text: string, record: any) {
        return (
          <span style={{ color: StatusColor?.[record.status] }}>
            {StatusTips[record.status] || text}
          </span>
        );
      },
    });
    arr.push({
      title: t('单位'),
      dataIndex: 'unit',
      key: 'unit',
      align: 'center',
      render(text: string, record: any) {
        return <span style={{ color: StatusColor?.[record.status] }}>{text}</span>;
      },
    });
    arr.push({
      title: t('参考区间'),
      dataIndex: 'referenceItem',
      key: 'referenceItem',
      align: 'center',
      render(text: string, record: any) {
        return <span style={{ color: StatusColor?.[record.status] }}>{text}</span>;
      },
    });

    return arr;
  };

  return speechFilterMakeData?.inspectionReport?.length ? (
    <SpeechCard titleId="inspectionReport" title="检验报告">
      <div className={styles['speech-discharge']}>
        {speechFilterMakeData?.inspectionReport?.map((item: any) => (
          <SpeechStep
            key={item.id}
            id={`inspectionReport_${item.id}`}
            time={`${t('检验时间')}：${item.recordTime || '-'}`}
          >
            <SpeechStepCard
              padding="16px 16px 4px 16px"
              disabled={!!item.imagePath && !!item.imagePath.length}
            >
              <SpeechField
                title="报告图片"
                border={false}
                type="IMAGE"
                imagesSource={item.imagePath}
              />
            </SpeechStepCard>
            <SpeechStepCard
              disabled={!!item.description || !!item?.type || !!item?.zhInspectionItems}
            >
              <SpeechField title="检验项目" border description={item?.type} />
              {item?.zhInspectionItems?.length ? (
                <SpeechField type="OTHER" border title="检验结果">
                  {/* <Table
                    rowKey="id"
                    dataSource={item?.zhInspectionItems}
                    columns={clacTableColumns(item?.zhInspectionItems?.[0])}
                    pagination={false}
                  /> */}

                  <InspectionItemsTable showLabel={false} data={item?.zhInspectionItems || []} />
                </SpeechField>
              ) : null}
              <SpeechField title="描述" border={false} description={item.description} />
            </SpeechStepCard>
          </SpeechStep>
        ))}
      </div>
    </SpeechCard>
  ) : null;
};

export default SpeechInspect;

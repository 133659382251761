import PicSmallerUpload from '@/pages/combinedWard/picSmaller/components/PicSmallerUpload';
import CompressImage from '@/pages/patient/patientOcrImage/components/CompressImage';
import {
  httpImageProcessQueryPatientTask,
  httpImageProcessQueryTaskDetails,
  httpImageProcessSaveTask,
  httpImageRecognitionCreate,
  httpImageRecognitionFind,
} from '@/services/imageProcess';
import { CompressFilesProps } from '@/utils/picControler/ImageBase';
import { message } from '@sinohealth/butterfly-ui-components';
import classNames from 'classnames';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { OcrContext } from '../PatientTabs/components/TabItem';
import styles from './index.less';

interface SingleCompressProps {
  accept?: string;
  recordId?: string;
  fileType?: string;
  currentIndex?: any; // 当前图片的索引
  onUpdate?: (url?: string) => void;
  onCanOcr?: (bol: boolean) => void;
}

/**
 * @description: 当个压缩上传
 * @author: KaifengLi
 * @version: v2.27.0
 * @Date: 2024-11-21 10:34:42
 */
const SingleCompress: React.FC<SingleCompressProps> = (props) => {
  const { accept, recordId, currentIndex, fileType, onUpdate, onCanOcr } = props;

  const { recognitTaskId, setRecognitTaskId } = useContext(OcrContext);
  const [params] = useSearchParams();
  const patientId: string = params.get('patientId') || '';
  const id = params.get('id') || ''; // 患者详情的患者id
  const [patientTasks, setPatientTasks] = useState<any[]>([]);
  const [saveLoading, setSaveLoading] = useState(false);

  // 使用 useRef 来存储定时器和任务 ID 列表
  const intervalRef = useRef<any>(null);
  const taskIdsRef = useRef<string[]>([]);
  const updateCallBack = useRef<any>(null);
  const taskidToIndexRef: any = {};

  useEffect(() => {
    updateCallBack.current = onUpdate;
  }, [onUpdate]);

  /** 保存图片处理任务 */
  const httpImageProcessSaveTaskReq = async (file: CompressFilesProps, taskId: string, i: number) => {
    try {
      const formDate = new FormData();
      formDate.append('file', file?.file);
      formDate.append('recognitionTaskId', taskId);
      const res: any = await httpImageProcessSaveTask(formDate);
      taskidToIndexRef[res.id] = currentIndex + i + 1;
      // console.log({ id: res.id, i, taskidToIndexRef });
      taskIdsRef.current.push(res.id);
      if (res?.status === 'completed') {
        if (res?.isRepeat) {
          !fileType && message.success(`文件【${file.file.name}】已上传`);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  /** 查询患者图片处理任务编号 */
  const httpImageProcessQueryPatientTaskReq = async () => {
    try {
      const res: any = await httpImageProcessQueryPatientTask({
        patientId: patientId || id,
        taskType: 'single',
        statusList: ['in_processing'],
        fileType,
      });
      const { taskList = [] } = res || {};

      if (taskIdsRef.current.length > 0 && taskList.length !== taskIdsRef.current.length) {
        const ids = taskList?.map((item: any) => item.id) || [];
        const completedFiles = taskIdsRef.current.filter((el: any) => !ids.includes(el));

        if (!completedFiles.length) return;

        completedFiles.forEach((item: any) => {
          setTimeout(() => {
            httpImageProcessQueryTaskDetailsReq(item);
          }, 0);
        });

        taskIdsRef.current = completedFiles.length
          ? taskIdsRef.current.filter((el: any) => !completedFiles.includes(el))
          : [];

        if (taskIdsRef.current.length === 0) {
          onCanOcr?.(true);
        }
      }

      if (taskList.length === 0) {
        clearInterval(intervalRef.current as number);
      } else {
        clearInterval(intervalRef.current as number);
        interFn();
        taskIdsRef.current = taskList.map((el: any) => el.id);
        onCanOcr?.(false);
      }

      setPatientTasks(taskList);
    } catch (err) {
      console.log(err);
      clearInterval(intervalRef.current as number);
    }
  };

  /** 查询图片处理任务详情 */
  const httpImageProcessQueryTaskDetailsReq = async (taskId: string) => {
    try {
      const res: any = await httpImageProcessQueryTaskDetails({ taskId });
      const { status } = res;
      if (status !== 'in_processing') {
        if (status === 'completed') {
          message.success('图片处理成功');
          // console.log(taskidToIndexRef);
          updateCallBack?.current?.(res?.afterFilePath, taskidToIndexRef[taskId]);
        }
        if (status === 'unqualified') {
          message.error('图片不清晰，请重新上传');
        }
        if (status === 'fail') {
          message.error(res?.failReason || '图片处理失败');
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  /** 获取患者当前已有的智能识别任务 */
  const httpImageRecognitionFindReq = () => {
    return new Promise((resolve, reject) => {
      const patientIdToUse = patientId || id;
      const taskParams = {
        patientId: patientIdToUse,
        taskType: 'single',
        moduleType: fileType || undefined,
        recordId,
      };

      if (recognitTaskId) {
        resolve(recognitTaskId);
      } else {
        httpImageRecognitionFind(taskParams)
          .then(async (existingTask: any) => {
            console.log('获取患者当前已有的智能识别任务:', existingTask);
            if (!existingTask) {
              const newTask: any = await httpImageRecognitionCreate(taskParams);
              console.log('创建图片智能识别任务:', newTask);
              setRecognitTaskId(newTask);
              resolve(newTask);
            } else {
              setRecognitTaskId(existingTask?.id);
              resolve(existingTask?.id);
            }
          })
          .catch((error) => {
            console.error('获取或创建图片智能识别任务时出错:', error);
            reject();
          });
      }
    });
  };

  /** 轮询图片任务列表 */
  const interFn = () => {
    intervalRef.current = setInterval(async () => {
      httpImageProcessQueryPatientTaskReq();
      if (intervalRef.current) clearInterval(intervalRef.current);
    }, 2000);
  };

  /** 开始上传 */
  const handleStartUpload = async (files: CompressFilesProps[]) => {
    // 防止重复点击
    if (saveLoading) return;

    // 1. 在有上传文件时,先判断是否有智能识别任务
    const findTask: any = await httpImageRecognitionFindReq();
    // console.log({ findTask });
    if (!findTask) return;

    clearInterval(intervalRef.current);
    setSaveLoading(true);

    // 清空之前的任务ID列表
    intervalRef.current = null;
    // 使用 Promise.all 等待所有请求完成
    Promise.all(files.map((file, index) => {
      return (function (i) {
        return httpImageProcessSaveTaskReq(file, findTask, i); // 使用捕获的 i
      }(index));
    }))
      .then(() => {
        if (!intervalRef.current) httpImageProcessQueryPatientTaskReq();
        setSaveLoading(false);
      })
      .catch((error) => {
        console.error('Error processing files:', error);
        setSaveLoading(false);
      });
  };

  return (
    <div data-no-drag="true" className={classNames('filtered', styles['single-compress'])}>
      {patientTasks?.map((el, index) => (
        <CompressImage
          key={el?.id}
          progress={el?.progress}
          index={index}
          loading
          deleted={false}
          edit={false}
          blob={!!el?.compress}
          tip={`图片检测中 ${el?.progress || 0}%`}
          picSource={patientTasks?.map((item) => item?.beforeFilePath) as string[]}
          url={el?.beforeFilePath}
        />
      ))}
      <PicSmallerUpload
        auto={false}
        accept={accept}
        multiple
        loading={saveLoading}
        uploadTip="图片压缩中"
        onChange={(urls, file, fileLen?: number) => {
          if (urls) {
            console.log(urls);
            updateCallBack?.current?.(urls);
          }
          if (file.length) {
            handleStartUpload(file);
          }
        }}
      />
    </div>
  );
};

export default SingleCompress;

import {
  ApiOutlined,
  AuditOutlined,
  BankOutlined,
  BarChartOutlined,
  ChromeOutlined,
  CodeSandboxOutlined,
  ContactsOutlined,
  ContainerOutlined,
  ControlOutlined,
  DeploymentUnitOutlined,
  DesktopOutlined,
  ExperimentOutlined,
  FileImageOutlined,
  HomeOutlined,
  MutedOutlined,
  NotificationOutlined,
  ProfileOutlined,
  ProjectOutlined,
  QrcodeOutlined,
  ShopOutlined,
  SlackOutlined,
  SolutionOutlined,
  SwitcherOutlined,
  TeamOutlined,
  UsergroupAddOutlined,
  UserOutlined,
} from '@ant-design/icons';
import React from 'react';
const { NODE_ENV } = process.env;

export type MenuItem = {
  label: string;
  path?: string;
  code?: string; // 权限编码
  icon?: React.ReactNode;
  children?: MenuItem[];
};
const menuConfig: MenuItem[] = [
  {
    label: '我的首页',
    path: '/index',
    icon: <HomeOutlined />,
  },
  {
    label: '我的患者',
    path: '/patient/list',
    icon: <ContactsOutlined />,
    code: 'PatientManageList',
  },
  {
    label: '会诊中心',
    path: '/consultation-center/index',
    icon: <DesktopOutlined />,
    code: 'consultationCenterList',
  },
  {
    label: '合同',
    path: 'contract',
    icon: <TeamOutlined />,
    children: [
      {
        label: '合同审核',
        path: '/contract/verification',
        code: 'ContractVerificaList',
      },
      {
        label: '合同模板',
        path: '/contract/template',
        code: 'ContractTemplateList',
      },
    ],
  },
  {
    label: '会诊讲稿',
    path: '/speech-lecture/index',
    icon: <NotificationOutlined />,
    code: 'SpeechLectureList',
  },
  {
    label: 'MDT团队',
    path: '/mdt-team/index',
    icon: <UsergroupAddOutlined />,
    code: 'mdtTeamList',
  },
  {
    label: '授权邀请码',
    path: '/patient/patientInviteCode',
    icon: <QrcodeOutlined />,
    code: 'patientInviteCode',
  },
  /**********联合病房-联合病房MDT团队****************/
  {
    label: '团队会诊中心',
    path: '/combinedWard/mdtConsult',
    icon: <DesktopOutlined />,
    code: 'CombinedWardMdtConsu',
  },
  {
    label: '联合病房MDT团队',
    path: '/combinedWard/mdt',
    icon: <UsergroupAddOutlined />,
    code: 'CombinedWardMdt',
  },
  {
    label: '医生管理',
    path: '/doctor/list',
    icon: <UserOutlined />,
    code: 'DoctorList',
  },
  {
    label: '医助管理',
    path: '/assistantManage/list',
    icon: <ExperimentOutlined />,
    code: 'AssistantManageList',
  },
  {
    label: '病例列表',
    path: '/case-history/index',
    icon: <ContainerOutlined />,
    code: 'CaseHistoryList',
  },
  // 联合病房病例库
  {
    label: '项目病例库',
    path: '/case-history/case',
    icon: <DeploymentUnitOutlined />,
    code: 'CombinedCaseBank',
  },
  {
    label: '共享病例库',
    path: '/case-history/share',
    icon: <DeploymentUnitOutlined />,
    code: 'ShareCaseHistoryList',
  },
  {
    label: '医助管理',
    path: '/doctor/assistant/list',
    icon: <SolutionOutlined />,
    code: 'DoctorAssistantList',
  },
  {
    label: '机构管理',
    path: '/organizationalManagement/institutionAdmin',
    icon: <BankOutlined />,
    // code: 'InstitutionAdmin',
    children: [
      {
        label: '机构信息',
        path: '/organizational',
        code: 'OrganizationalList',
      },
      {
        label: '项目信息',
        path: '/project',
        code: 'ProjectList',
      },
    ],
  },
  {
    label: '项目信息', // 机构管理员下的项目信息
    icon: <BankOutlined />,
    path: '/organizational/project',
    code: 'OrgProjectList',
  },
  {
    label: '系统管理',
    path: 'sys',
    icon: <CodeSandboxOutlined />,
    children: [
      {
        label: '账号管理',
        path: '/sys/accountNumber',
        code: 'AccountNumber',
      },
      {
        label: '角色管理',
        path: '/sys/roleAdmin',
        code: 'RoleAdmin',
      },
      {
        label: '菜单管理',
        path: '/sys/menuAdmin',
        code: 'MenuAdmin',
      },
      {
        label: '权限组管理',
        path: '/sys/groupAdmin',
        code: 'GroupAdmin',
      },
      {
        label: '病种管理',
        path: '/sys/diseaseTypeAdmin',
        code: 'DiseaseTypeAdmin',
      },
    ],
  },
  {
    label: '主数据管理',
    path: 'masterDataManagement',
    icon: <SlackOutlined />,
    children: [
      {
        label: '区域管理',
        path: '/masterDataManagement/regionAdmin',
        code: 'RegionAdmin',
      },
      {
        label: '医院管理',
        path: '/masterDataManagement/hospitalAdmin',
        code: 'HospitalAdmin',
      },
      {
        label: '科室管理',
        path: '/masterDataManagement/departmentAdmin',
        code: 'DepartmentAdmin',
      },
      {
        label: '职称管理',
        path: '/masterDataManagement/jobTitleAdmin',
        code: 'JobTitleAdmin',
      },

      {
        label: '系统标签管理',
        path: '/masterDataManagement/labelAdmin',
        code: 'LabelAdmin',
      },
    ],
  },
  {
    label: '医生信息库',
    path: '/doctor/info/list',
    icon: <ProfileOutlined />,
    code: 'DoctorInfoList',
  },
  // {
  //   label: 'MDT数据统计',
  //   path: '/dataBase',
  //   icon: <BarChartOutlined />,
  //   code: 'mdtDataStatistics',
  // },
  {
    label: 'MDT数据统计',
    path: '/mdt-statistics',
    icon: <BarChartOutlined />,
    code: 'MdtStatistics',
  },
  {
    label: 'BI配置',
    path: '/bIModule/bIConfiguration',
    icon: <ChromeOutlined />,
    code: 'BIConfiguration',
  },
  {
    label: '公众号网站管理',
    path: '/officialAccount/officialList',
    icon: <SwitcherOutlined />,
    code: 'OfficialList',
  },
  {
    label: '申请管理',
    path: 'applyAdmin',
    icon: <AuditOutlined />,
    children: [
      {
        label: '会诊申请',
        path: '/applyAdmin/consultationApply',
        code: 'ConsultationApply',
      },
      {
        label: '合作申请',
        path: '/applyAdmin/cooperationApply',
        code: 'CooperationApply',
      },
      {
        label: '入驻申请',
        path: '/settledApply/list',
        code: 'SettledApplyList',
      },
    ],
  },

  {
    label: 'MDT评分',
    path: '/scoreModule/scorelist',
    icon: <ShopOutlined />,
    code: 'Scorelist',
  },

  {
    label: '订单管理',
    path: '/orderAdmin/orderList',
    icon: <ShopOutlined />,
    code: 'OrderList',
  },
  {
    label: '交易流水',
    path: '/transactionFlow/transactionList',
    icon: <ShopOutlined />,
    code: 'TransactionList',
  },
  {
    label: '招募管理',
    path: '/consultationRecruitment/recruitmentManage',
    icon: <UsergroupAddOutlined />,
    code: 'RecruitmentManage',
  },
  {
    label: '报名管理',
    path: '/consultationRecruitment/signUp',
    icon: <MutedOutlined />,
    code: 'RecruitmentRegister',
  },
  {
    label: '招募列表',
    path: '/consultationRecruitment/recruitment',
    icon: <ControlOutlined />,
    code: 'RecruitmentDoctor',
  },
  {
    label: '表单库',
    icon: <ShopOutlined />,
    path: '/formily/list',
    code: 'FormilyList',
  },
  {
    label: '图像识别管理',
    icon: <FileImageOutlined />,
    path: '/combinedWard/imageRecognite',
    code: 'ImageRecognition',
  },
  {
    label: '随访管理',
    icon: <ApiOutlined />,
    path: '/followUp/list',
    code: 'FollowUpList',
  },
  {
    label: '活动管理',
    icon: <NotificationOutlined />,
    path: '/activity/list',
    code: 'ActivityManage',
  },
  {
    label: '活动用户管理',
    icon: <UsergroupAddOutlined />,
    path: '/activity/user',
    code: 'ActivityUserManage',
  },
  {
    label: '活动专题管理',
    icon: <ProjectOutlined />,
    path: '/activity/subject',
    code: 'ActivitySubjectManag',
  },
  {
    label: '活动数据列表',
    icon: <ShopOutlined />,
    path: '/activity/statistics',
    code: 'AcStatisticManage',
  },
  {
    label: '活动运营分析',
    icon: <ProjectOutlined />,
    path: '/activity/operational',
    code: 'AcOperatorManage',
  },
  {
    label: '质控评分管理',
    icon: <ShopOutlined />,
    path: '/qualityControlScore/list',
    code: 'ScoreStrategy',
  },
  // {
  //   label: '联盟详情',
  //   path: '/applyAdmin/allianceDetails',
  //   code: 'AllianceDetailsNew',
  //   icon: <span className="iconfont icon-project"></span>,
  // },
];

export default menuConfig;

import useDictOption from '@/hooks/dict/useDictOptions';
import { useLocales } from '@/Locales';
import { useTimeLineSave } from '@/pages/combinedWard/hook/useTimeline';
import {
  Button,
  DatePicker,
  Form,
  Input,
  Modal,
  Select,
} from '@sinohealth/butterfly-ui-components';
import dayjs from 'dayjs';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import AssociatedDetails from '../AssociatedDetails';
import FormLabel from '../FormLabel';

export interface EditFormProps {
  edit?: boolean;
  button?: boolean;
  onSubmit?: () => void;
}

/**
 * @description: 联合病房-时间轴讲稿-病例时间轴-影像信息-FORM
 * @author: KaifengLi
 * @version: v2.24.0
 * @Date: 2024-10-09 10:31:22
 */
const ImageForm = forwardRef((props: EditFormProps, ref: any) => {
  const { t } = useLocales();

  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);
  const options = useDictOption('imageType');
  const { patientId, setTimelineParams, setFormParams, formParams, saveSuccess } =
    useTimeLineSave();

  useImperativeHandle(ref, () => ({
    open: (val: any) => {
      setOpen(true);
      let recordTime = val.recordTime;
      if (val.recordTime === '9999-12-31') {
        recordTime = dayjs();
      } else if (val.recordTime === '') {
        recordTime = null;
      } else {
        recordTime = dayjs(val.recordTime);
      }
      form.setFieldsValue({
        ...val,
        recordTime,
      });
      setFormParams(val);
    },
  }));

  const handleSubmit = () => {
    form.validateFields().then((formValue) => {
      setTimelineParams({
        patientId,
        timeLineList: [
          {
            dateStr: formValue.recordTime.format('YYYY-MM'),
            imageReportRecords: [
              {
                ...formParams,
                ...formValue,
                recordTime: formValue.recordTime.format('YYYY-MM-DD'),
                nodeType: 'IMAGE_REPORT',
              },
            ],
          },
        ],
      });
    });
  };

  useEffect(() => {
    if (saveSuccess) {
      props?.onSubmit?.();
      setOpen(false);
    }
  }, [saveSuccess]);

  return (
    <>
      {props?.button && (
        <Button
          type="primary"
          style={{ background: '#ecf3ff', display: 'flex', alignItems: 'center' }}
          icon={<span className="iconfont icon-yingxiang" style={{ marginRight: 6 }} />}
          ghost
          onClick={() => {
            props?.edit && setOpen(true);
          }}
        >
          影像信息
        </Button>
      )}
      <Modal
        title={`${formParams?.id ? t('编辑') : t('新增')} ${t('影像信息')}`}
        open={open}
        maskClosable={false}
        style={{ top: 20 }}
        onOk={handleSubmit}
        onCancel={() => setOpen(false)}
        afterClose={() => {
          form.resetFields();
          setFormParams({});
        }}
      >
        <Form
          name="basic"
          form={form}
          layout="vertical"
          colon={false}
          labelCol={{ span: 12 }}
          autoComplete="off"
        >
          <Form.Item name="id" hidden>
            <Input />
          </Form.Item>
          <Form.Item
            name="recordTime"
            rules={[{ required: true, message: t('请选择检查时间') }]}
            labelCol={{ span: 12 }}
            label={<FormLabel label="检查时间" />}
          >
            <DatePicker style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item labelCol={{ span: 12 }} label={<FormLabel label="检查部位" />} name="parts">
            <Input.TextArea
              autoSize={{ minRows: 4, maxRows: 4 }}
              maxLength={200}
              showCount
              placeholder={t('请输入')}
            />
          </Form.Item>
          <Form.Item labelCol={{ span: 12 }} label={t('影像类型')} name="imagesType">
            <Select options={options} placeholder={t('请选择')} />
          </Form.Item>
          <Form.Item
            label={<FormLabel label="检查结论" />}
            name="reportDiagnose"
            labelCol={{ span: 12 }}
            rules={[{ required: true, message: t('请输入') }]}
          >
            <Input.TextArea
              maxLength={1000}
              placeholder={t('请输入')}
              showCount
              autoSize={{ minRows: 4, maxRows: 4 }}
            />
          </Form.Item>
          <Form.Item
            labelCol={{ span: 12 }}
            label={<FormLabel label="关联档案详情" tooltip />}
            name="refContent"
          >
            <AssociatedDetails
              refContent={formParams?.refContent}
              refId={formParams?.refId}
              refType={formParams?.refType}
              onSubmit={(id, type, time) => {
                setFormParams({
                  ...formParams,
                  refId: id || undefined,
                  refType: type || undefined,
                  refContent: time || undefined,
                });
                form.setFieldsValue({ refContent: time });
              }}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
});

export default ImageForm;

/*
 * @Description: 讲稿演示-主要内容-患者信息
 * @Author: likaifeng
 * @Date: 2023-11-30 16:12:41
 * @LastEditTime: 2025-03-24 13:57:52
 * @LastEditors: 李开锋
 */
import { useLocales } from '@/Locales';
import { speechFilterMakeDataAtom } from '@/store/lecture';
import { maskMiddleCharacters } from '@/utils';
import { Popover } from '@sinohealth/butterfly-ui-components';
import React from 'react';
import { useRecoilValue } from 'recoil';
import SpeechCard from '../SpeechCard';
import styles from './index.less';

/**
 * @description: 讲稿演示-主要内容-患者信息
 * @author: likaifeng
 * @return {*}
 * @Date: 2023-12-04 14:27:51
 */
const SpeechPatient: React.FC = () => {
  const speechFilterMakeData = useRecoilValue(speechFilterMakeDataAtom);
  const { t } = useLocales();
  return (
    <SpeechCard id="baseInfo" title="患者信息">
      <div className={styles['speech-patient']}>
        <div className={styles.container}>
          <ul className={styles.patient}>
            <li className={styles['patient-item']}>
              <span className={styles['item-label']}>{t('姓名')}</span>
              <span className={styles['item-name']}>
                {maskMiddleCharacters(speechFilterMakeData?.baseInfo?.name) || t('未填写')}
              </span>
            </li>
            <li className={styles['patient-item']}>
              <span className={styles['item-label']}>{t('性别')}</span>
              <span className={styles['item-name']}>
                {t(speechFilterMakeData?.baseInfo?.gender) || t('未填写')}
              </span>
            </li>
            <li className={styles['patient-item']}>
              <span className={styles['item-label']}>{t('年龄')}</span>
              <span className={styles['item-name']}>
                {speechFilterMakeData?.baseInfo?.age || t('未填写')}
              </span>
            </li>
            <li className={styles['patient-item']}>
              <span className={styles['item-label']}>{t('医院')}</span>
              <Popover content={speechFilterMakeData?.baseInfo?.hospitalName || t('未填写')}>
                <span className={styles['item-name']}>
                  {speechFilterMakeData?.baseInfo?.hospitalName || t('未填写')}
                </span>
              </Popover>
            </li>
            <li className={styles['patient-item']}>
              <span className={styles['item-label']}>{t('肿瘤分期')}</span>
              <span className={styles['item-name']}>
                {speechFilterMakeData?.baseInfo?.tumorStage || t('未填写')}
              </span>
            </li>
          </ul>
          <div className={styles.footer}>
            <span className={styles['footer-label']}>{t('最新诊断')}</span>
            <Popover content={speechFilterMakeData?.baseInfo?.latestDiagnosis || t('未填写')}>
              <span className={styles['footer-desc']}>
                {speechFilterMakeData?.baseInfo?.latestDiagnosis || t('未填写')}
              </span>
            </Popover>
          </div>
        </div>
      </div>
    </SpeechCard>
  );
};

export default SpeechPatient;

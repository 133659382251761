import { Form, FormItem } from '@/components/BaseForm';
import Label from '@/components/Label';
import Text from '@/components/Text';

import { useLocales } from '@/Locales';
import { PatientInfoAtom, PatientInfoStatus } from '@/pages/patient/detail';
import RecordImgUpload from '@/pages/patient/detail/components/RecordImgUpload';
import TabHeader, { TabHeaderInput } from '@/pages/patient/detail/components/TabHeader';
import { useInitOcrRes } from '@/pages/patient/hooks/useOcr';
import { savePatientOther } from '@/services/patient';
import { Form as AntdForm, Button, Input } from '@sinohealth/butterfly-ui-components';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

const requiredRule = { required: true, message: '该字段为必填项' };

const OtherTab = (props: any) => {
  const { data } = props;
  const [form] = AntdForm.useForm();
  const [readOnly, setReadOnly] = useState(false);
  const { t } = useLocales();
  const patientInfo = useRecoilValue(PatientInfoAtom);
  const [patientInfoStatus, setPatientInfoStatus] = useRecoilState(PatientInfoStatus);
  useEffect(() => {
    setReadOnly(!!data?.id);
  }, []);

  const [isEditing, setIsEditing] = useState(false);
  useEffect(() => {
    return () => {
      // 组件销毁时，如果是编辑状态，把全局的可编辑状态还原
      if (isEditing) {
        setPatientInfoStatus({
          edit: true,
        });
      }
    };
  }, [isEditing]);

  /** 患者详情图片智能识别结果回填处理 */
  const { handleSecondComfirm, originFormValues, saveLoading, setSaveLoading } = useInitOcrRes({
    form,
    isEditing: readOnly,
    reportType: 'otherInformation',
  });

  const handleChangeReadOnly = () => {
    const newReadOnly = !readOnly;
    setReadOnly(newReadOnly);
    setIsEditing(!newReadOnly);
    setPatientInfoStatus({
      edit: newReadOnly,
    });
    if (newReadOnly) {
      patientInfo.update();
    }
    form.resetFields();
  };
  const handleSubmit = () => {
    form
      .validateFields()
      .then((formValue) => {
        const params = {
          ...originFormValues,
          ...formValue,
          patientId: patientInfo.patient.id,
        };
        setSaveLoading(true);
        savePatientOther(params)
          .then(() => {
            patientInfo.update();
            handleChangeReadOnly();
          })
          .finally(() => {
            setSaveLoading(false);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const action = readOnly ? (
    <a onClick={handleChangeReadOnly}>{t('编辑')}</a>
  ) : (
    <>
      <Button
        type="link"
        style={{ padding: 0 }}
        disabled={saveLoading}
        onClick={() => handleSecondComfirm(handleChangeReadOnly, !data?.id)}
      >
        <Text>取消</Text>
      </Button>
      <Button
        type="link"
        style={{ padding: 0 }}
        disabled={saveLoading}
        onClick={() => handleSecondComfirm(handleSubmit)}
      >
        <Text>保存</Text>
      </Button>
    </>
  );
  const mapImage = (urls: string) => {
    return <RecordImgUpload originUrl={urls} value={urls} disabled />;
  };

  return (
    <Form
      className="record-form"
      form={form}
      readOnly={readOnly}
      initialValues={data}
      layout={readOnly ? 'horizontal' : 'vertical'}
    >
      <FormItem name="id" hidden>
        <Input />
      </FormItem>
      <FormItem
        style={{ padding: 0 }}
        transformText={(text) => (
          <TabHeader
            disabled
            label={t('资料时间')}
            title={text ? dayjs(text).format('YYYY-MM-DD') : ''}
            action={action}
          />
        )}
        name="informationDate"
        span={24}
        rules={[requiredRule]}
      >
        <TabHeaderInput label={t('资料时间')} action={action} />
      </FormItem>
      <FormItem name="reportImage" transformText={mapImage}>
        <RecordImgUpload
          originUrl={data.reportImage}
          fileType="otherInformation"
          recordId={data?.id}
        />
      </FormItem>
      <FormItem
        label={<Label>资料类型</Label>}
        name="informationType"
        required={!readOnly}
        rules={[requiredRule]}
      >
        <Input maxLength={20} showCount />
      </FormItem>
      <FormItem label={<Label>描述</Label>} name="description">
        <Input.TextArea maxLength={1000} showCount autoSize={{ minRows: 4, maxRows: 4 }} />
      </FormItem>
    </Form>
  );
};

export default OtherTab;

import { request, requestFd } from '@/common/request';
import { clientPrefix, getTokenParams } from '@/config/base';
import { UCenter } from '@/services/user/data';
import { AxiosRequestConfig } from 'axios';

const prefix = '/api/oauth';
const apiPrefix = '';
const pharmacyPrefix = '/api/pharmacy';

export const getToken = (params: any) => {
  const path = `${prefix}/token`;
  return requestFd.post(path, { ...params, ...getTokenParams });
};

export const getUserLinkChain = () => request.get(`${pharmacyPrefix}/userCenter/listUserLinkChain`);

export const sendPhoneCode = (phoneNo: string, resetPwd = false) =>
  request.post('/uaa/captcha/sms', {
    phone: phoneNo,
    resetPwd,
  });

export const getVerifyCode = (phoneNo: string) =>
  request.post('/sms/sendVerifyCode', {
    phone: phoneNo,
  });

export function checkVerifyCode(data: any) {
  return request.post('/sms/checkVerifyCode', data);
}

/** 获取密码 */
export function systemUserPassword(data: any) {
  return request.post('/system/user/password', data);
}

export const switchChain = (params: any) => request.post(`${prefix}/switchChain`, params);
/**
 * 通过验证码修改个人密码
 * @param params
 * @returns
 */
export const resetPassword = (params: any) => {
  return request.post(`${pharmacyPrefix}/userCenter/resetPassword`, params);
};
/**
 * 获取个人信息
 * @param params
 * @returns
 */
export const getUserInfo = (params: any) => {
  return request.post<UCenter.UserInfo, any>(`${clientPrefix}/personalCenter/detail`, params);
};

/**
 * 修改个人密码
 * @param params
 * @returns
 */
export const updateUserPassword = (params: UCenter.UpdatePasswordReq) =>
  request.post('/uaa/user/updatePassword', params);

/**
 * 修改个人资料
 * @param params
 * @returns
 */
export const updateUserInfo = (params: UCenter.UpdateUserInfoReq) => {
  return request.post(`${clientPrefix}/personalCenter/edit`, params);
};

/**
 * 修改个人资料
 * @param params
 * @returns
 */
export const getUserResourceByScope = () => {
  return request.get<UCenter.UserResourceByScopeResponse, any>(
    '/api/rbac/menu/getUserResourceByScope/zmn-rx-oms-server?version=v1',
  );
};

export const doLogin = (params: any, config: AxiosRequestConfig = {}) => {
  // return axios.post(`${loginHost}/sso/doLogin`, params, config);
  return requestFd.post('/uaa/sso/doLogin', params, config);
};

export const getListOrganize = (params: any, config: AxiosRequestConfig = {}) => {
  return request.post('/uaa/user/listOrganize', params);
};

/**
 * 查询个人企业资料
 * @param params
 * @returns
 */
export const getUpdateUserInfoEmployee = () => {
  return request.get('/uaa/user/profile/employee');
};

/**
 * 更新个人企业资料
 * @param params
 * @returns
 */
export const UpdUpdateUserInfoEmployee = (params: UCenter.UpdateUserInfoEmployeeReq) => {
  return request.post('/uaa/user/update/profile/employee', params);
};

export const getLoginPage = (params: any) => {
  return request.post('/uaa/user/login/page', params, {
    // @ts-ignore
    isReturnAllData: true,
  });
};

export const getMenuPermission = () => {
  return request.get('/uaa/user/permission');
};

/**
 * @description: 登录功能
 * @author: likaifeng
 * @param {any} params
 * @return {*}
 * @Date: 2023-11-17 10:50:47
 */
export const httpLogin = (params: any) => {
  return request.post(`${apiPrefix}/login`, params, {
    // @ts-ignore
    hiddenErrorMsg: true,
  });
};

/**
 * 获取登录配置
 * @param params
 * @returns
 */
export const httpSystemSetting = () => {
  return request.get(`${apiPrefix}/system/login/setting`);
};

/**
 * @description: 获取系统管理-用户基础信息表信息成功
 * @author: likaifeng
 * @param {any} params
 * @return {*}
 * @Date: 2023-11-17 10:50:47
 */
export const httpGetIdentityById = (id: number | string) => {
  return request.post(`${apiPrefix}/system/user/info`, { id });
};

/**
 * @description: 根据用户ID查询机构信息 - 成功
 * @author: likaifeng
 * @param {any} params
 * @return {*}
 * @Date: 2023-11-17 10:50:47
 */
export const httpGetAgencyListById = (id: number | string) => {
  return request.post(`${apiPrefix}/agency/getAgencyList`, { id });
};

/**
 * @description: 查询-用户默认进入机构
 * @author: likaifeng
 * @param {any} params
 * @return {*}
 * @Date: 2023-11-17 10:50:47
 */
export const httpGetDefaultAgecny = (id: number | string) => {
  return request.post(`${apiPrefix}/agency/getDefaultAgecny`, { id });
};

// 修改用户密码
export const httpUpdatePassword = (params: any) => {
  return request.post(`${apiPrefix}/system/user/updatePassword`, params);
};

export default {
  getToken,
  getUserLinkChain,
  switchChain,
};

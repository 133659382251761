import { useLocales } from '@/Locales';
import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import EditAndDelElement from '../EditAndDelElement';
import PreviewRelaElement from '../PreviewRelaElement';
import TriggerElement from '../TriggerElement';
import { VerticalProps } from '../Vertical';
import styles from './index.less';

export const greenType = ['PATHOLOGY_REPORT', 'INSPECTION_REPORT', 'GENETIC_TESTING'];
export const greenTypeEnum: any = {
  PATHOLOGY_REPORT: '病理信息',
  INSPECTION_REPORT: '检验信息',
  GENETIC_TESTING: '基因检测信息',
};
export const blueType = ['IMAGE_REPORT'];
export const purpleType = ['DIAGNOSIS', 'MDT_CONCLUSION', 'OTHER_INFORMATION', 'MEDICAL_REPORT'];
export const redType = [
  'DRUG_RECORD',
  'SURGICAL_RECORD',
  'TREATMENT_RADIOTHERAPY',
  'TREATMENT_OTHER_INFORMATION',
];

export const recordTimeFormate = (recordTime: string) => {
  return recordTime ? `${recordTime.split('-').slice(1).join('月')}日` : '';
};

/**
 * @description: 时间轴组件-新版设计稿-横向时间轴
 * @author: KaifengLi
 * @version: v2.28.0
 * @Date: 2024-12-25 14:07:55
 */
const Horizontal: React.FC<VerticalProps> = (props) => {
  const { edit, detail, onRela, onEdit, onDelete } = props;

  const lastElementRef = useRef<HTMLDivElement>(null);
  const [lastElementHeight, setLastElementHeight] = useState(0);
  const [showLine, setShowLine] = useState(true);
  const { t } = useLocales();

  const handleCloseLine = () => {
    setShowLine(!showLine);
  };

  useEffect(() => {
    if (lastElementRef.current && showLine) {
      const rect = lastElementRef.current.getBoundingClientRect();
      setLastElementHeight(rect?.height);
    }
  }, [showLine, detail]);

  if (detail?.yearMonth === '9999-12' && !edit) return null;

  return (
    <div
      className={styles.horizontal}
      style={{ '--lastElementHeight': `${lastElementHeight}px` } as any}
    >
      <div className={classNames(styles.month)}>
        {detail?.yearMonth === '9999-12' ? '未识别日期' : detail?.yearMonth}
      </div>
      <div className={classNames(styles.container, !showLine && styles['month-line'])}>
        {/* {TriggerElement(showLine, handleCloseLine, styles.close, 'HORIZONTAL')} */}
        <TriggerElement
          value={showLine}
          direction="HORIZONTAL"
          onChange={handleCloseLine}
          className={styles.close}
        />
        {detail?.data?.map((item: any, index: number) => (
          <React.Fragment key={item?.id}>
            {redType.includes(item?.nodeType) && (
              <div
                ref={detail.data.length - 1 === index ? lastElementRef : null}
                style={{ display: showLine ? 'block' : 'none' }}
                className={classNames(styles['axis-item'], styles.red)}
              >
                <div className={styles.day}>{recordTimeFormate(item?.recordTime)}</div>
                <div className={classNames(styles.timeline)}>
                  <div className={styles.header}>
                    {t('治疗信息')}
                    {/* {EditAndDelElement(item, onEdit, onDelete, detail?.yearMonth, edit)} */}
                    <EditAndDelElement
                      item={item}
                      onEdit={onEdit}
                      onDelete={onDelete}
                      dateStr={detail?.yearMonth}
                      edit={edit}
                    />
                  </div>
                  <div className={styles.axis}>
                    <div className={styles.detail}>
                      {item?.label}: {item?.content}
                    </div>
                    {/* {PreviewRelaElement(item, onRela)} */}
                    <PreviewRelaElement item={item} onRela={onRela} />
                  </div>
                </div>
              </div>
            )}
            {greenType.includes(item?.nodeType) && (
              <div
                ref={detail.data.length - 1 === index ? lastElementRef : null}
                style={{ display: showLine ? 'block' : 'none' }}
                className={classNames(styles['axis-item'], styles.green)}
              >
                <div className={styles.day}>{recordTimeFormate(item?.recordTime)}</div>
                <div className={classNames(styles.timeline)}>
                  <div className={styles.header}>
                    {t(greenTypeEnum[item?.nodeType])}
                    {/* {EditAndDelElement(item, onEdit, onDelete, detail?.yearMonth, edit)} */}
                    <EditAndDelElement
                      item={item}
                      onEdit={onEdit}
                      onDelete={onDelete}
                      dateStr={detail?.yearMonth}
                      edit={edit}
                    />
                  </div>
                  <div className={styles.axis}>
                    <div className={styles.detail}>
                      {item?.label}: {item?.content}
                    </div>
                    {/* {PreviewRelaElement(item, onRela)} */}
                    <PreviewRelaElement item={item} onRela={onRela} />
                  </div>
                </div>
              </div>
            )}
            {blueType.includes(item?.nodeType) && (
              <div
                ref={detail.data.length - 1 === index ? lastElementRef : null}
                style={{ display: showLine ? 'block' : 'none' }}
                className={classNames(styles['axis-item'], styles.blue)}
              >
                <div className={styles.day}>{recordTimeFormate(item?.recordTime)}</div>
                <div className={classNames(styles.timeline)}>
                  <div className={styles.header}>
                    {t('影像信息')}
                    {/* {EditAndDelElement(item, onEdit, onDelete, detail?.yearMonth, edit)} */}
                    <EditAndDelElement
                      item={item}
                      onEdit={onEdit}
                      onDelete={onDelete}
                      dateStr={detail?.yearMonth}
                      edit={edit}
                    />
                  </div>
                  <div className={styles.axis}>
                    <div className={styles.detail}>
                      {item?.label}: {item?.content}
                    </div>
                    {/* {PreviewRelaElement(item, onRela)} */}
                    <PreviewRelaElement item={item} onRela={onRela} />
                  </div>
                </div>
              </div>
            )}
            {purpleType.includes(item?.nodeType) && (
              <div
                ref={detail.data.length - 1 === index ? lastElementRef : null}
                style={{ display: showLine ? 'block' : 'none' }}
                className={classNames(styles['axis-item'], styles.purple)}
              >
                <div className={styles.day}>{recordTimeFormate(item?.recordTime)}</div>
                <div className={classNames(styles.timeline)}>
                  <div className={styles.header}>
                    {t('其他信息')}
                    {/* {EditAndDelElement(item, onEdit, onDelete, detail?.yearMonth, edit)} */}
                    <EditAndDelElement
                      item={item}
                      onEdit={onEdit}
                      onDelete={onDelete}
                      dateStr={detail?.yearMonth}
                      edit={edit}
                    />
                  </div>
                  <div className={styles.axis}>
                    <div className={styles.detail}>
                      {item?.label}: {item?.content}
                    </div>
                    {/* {PreviewRelaElement(item, onRela)} */}
                    <PreviewRelaElement item={item} onRela={onRela} />
                  </div>
                </div>
              </div>
            )}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default Horizontal;

import { Form, FormItem } from '@/components/BaseForm';
import Label from '@/components/Label';
import Text from '@/components/Text';
import { useLocales } from '@/Locales';
import { PatientInfoAtom, PatientInfoStatus } from '@/pages/patient/detail';
import RecordImgUpload from '@/pages/patient/detail/components/RecordImgUpload';
import TabHeader, { TabHeaderInput } from '@/pages/patient/detail/components/TabHeader';
import { useInitOcrRes } from '@/pages/patient/hooks/useOcr';
import { addPatientDrugRecords, updatePatientDrugRecords } from '@/services/patient';
import { Form as AntdForm, Button, DatePicker, Input } from '@sinohealth/butterfly-ui-components';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

const requiredRule = { required: true, message: '该字段为必填项' };

const MedicalRecordsItem = (props: any) => {
  const { data } = props;
  const [form] = AntdForm.useForm();
  const [readOnly, setReadOnly] = useState(false);
  const patientInfo = useRecoilValue(PatientInfoAtom);
  const [patientInfoStatus, setPatientInfoStatus] = useRecoilState(PatientInfoStatus);
  const { t } = useLocales();

  useEffect(() => {
    setReadOnly(!!data?.id);
  }, []);

  const [isEditing, setIsEditing] = useState(false);
  useEffect(() => {
    return () => {
      // 组件销毁时，如果是编辑状态，把全局的可编辑状态还原
      if (isEditing) {
        setPatientInfoStatus({
          edit: true,
        });
      }
    };
  }, [isEditing]);

  /** 患者详情图片智能识别结果回填处理 */
  const { handleSecondComfirm, saveLoading, originFormValues, setSaveLoading, recognitTaskId } =
    useInitOcrRes({
      form,
      isEditing: readOnly,
      reportType: 'drugRecord',
    });

  const handleChangeReadOnly = () => {
    const newReadOnly = !readOnly;
    setReadOnly(newReadOnly);
    setPatientInfoStatus({
      edit: newReadOnly,
    });
    setIsEditing(!newReadOnly);
    if (newReadOnly) {
      patientInfo.update();
    }
    form.resetFields();
  };

  const handleSubmit = () => {
    form
      .validateFields()
      .then((formValue) => {
        const { medicationTime, ...otherFormValue } = formValue;
        const params = {
          ...originFormValues,
          ...otherFormValue,
          patientId: patientInfo.patient.id,
          startMedicationTime: medicationTime?.[0] ? medicationTime[0].format('YYYY-MM-DD') : '',
          endMedicationTime: medicationTime?.[1] ? medicationTime[1].format('YYYY-MM-DD') : '',
        };
        setSaveLoading(true);
        const api = formValue?.id ? updatePatientDrugRecords : addPatientDrugRecords;
        if (!formValue?.id) {
          params.recognitionTaskId = recognitTaskId;
        }
        api(params)
          .then(() => {
            patientInfo.update();
            handleChangeReadOnly();
          })
          .finally(() => {
            setSaveLoading(false);
          });
      })
      .catch(() => {});
  };
  const action = readOnly ? (
    <a onClick={handleChangeReadOnly}>
      <Text>编辑</Text>
    </a>
  ) : (
    <>
      <Button
        type="link"
        style={{ padding: 0 }}
        disabled={saveLoading}
        onClick={() => handleSecondComfirm(handleChangeReadOnly, !data?.id)}
      >
        <Text>取消</Text>
      </Button>
      <Button
        type="link"
        style={{ padding: 0 }}
        disabled={saveLoading}
        onClick={() => handleSecondComfirm(handleSubmit)}
      >
        <Text>保存</Text>
      </Button>
    </>
  );
  const mapImage = (urls: string) => {
    return <RecordImgUpload originUrl={urls} value={urls} disabled />;
  };
  const formValue = {
    ...data,
    medicationTime:
      data.startMedicationTime && data.endMedicationTime
        ? [data.startMedicationTime, data.endMedicationTime].map(dayjs)
        : null,
  };
  const getDateText = (item: any) => {
    return item ? item.map((date: any) => date.format('YYYY-MM-DD')).join('至') : '--';
  };
  return (
    <Form
      className="record-form"
      form={form}
      readOnly={readOnly}
      initialValues={formValue}
      layout={readOnly ? 'horizontal' : 'vertical'}
    >
      <FormItem name="id" hidden>
        <Input />
      </FormItem>
      <FormItem
        style={{ padding: 0 }}
        transformText={(text) => (
          <TabHeader
            label={<Text>医嘱时间</Text>}
            title={text ? dayjs(text).format('YYYY-MM-DD') : ''}
            action={action}
          />
        )}
        name="recordTime"
        span={24}
        rules={[requiredRule]}
      >
        <TabHeaderInput label={<Text>医嘱时间</Text>} action={action} />
      </FormItem>
      <FormItem name="imagePath" transformText={mapImage}>
        <RecordImgUpload originUrl={data.imagePath} fileType="drugRecord" recordId={data?.id} />
      </FormItem>
      <FormItem label={<Label>用药时期</Label>} name="medicationTime" transformText={getDateText}>
        <DatePicker.RangePicker
          placeholder={[t('请选择用药开始时间'), t('请选择用药结束时间')]}
          style={{ width: 400 }}
        />
      </FormItem>
      <FormItem label={<Label>用药方案</Label>} name="drugPlan">
        <Input.TextArea maxLength={1000} showCount autoSize={{ minRows: 4, maxRows: 4 }} />
      </FormItem>
      <FormItem label={<Label>不良反应</Label>} name="adverseReactions">
        <Input.TextArea maxLength={1000} showCount autoSize={{ minRows: 4, maxRows: 4 }} />
      </FormItem>
      <FormItem label={<Label>治疗效果</Label>} name="treatmentEffect">
        <Input.TextArea maxLength={1000} showCount autoSize={{ minRows: 4, maxRows: 4 }} />
      </FormItem>
    </Form>
  );
};

export default MedicalRecordsItem;

import { getAgencyList } from '@/services/common';
import { httpProjectPatientAuthConfig } from '@/services/patient';
import { httpGetIdentityById } from '@/services/user';
import { combinedWardFlagAtom, projectConfigAtom, userInfoAtom } from '@/store/atom';
import { getSystemUserInfo } from '@/utils/cookies';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';

// 联合病房的权限码
export const COMBINEDWARDCODE = [
  'CombinedWardMdt',
  'CombinedWardMdtDetai',
  'CombinedWardMdtConsu',
  'CombinedCaseBank',
  'CombinedDisease',
  'CombinedQualityScore',
];

let lock = false;
const useCurrentLoginUserInfo = () => {
  const [userInfo, setUserInfo] = useRecoilState(userInfoAtom);
  // 是否是联合病房医生
  const [combinedWardFlag, setCombinedWardFlag] = useRecoilState(combinedWardFlagAtom);
  // 项目配置
  const setProjectConfig = useSetRecoilState(projectConfigAtom);
  const [isError, setIsError] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (userInfo || lock) {
      return;
    }
    fetchData();
  }, []);

  const fetchData = async () => {
    lock = true;
    try {
      const info = getSystemUserInfo(); // 登录后本地存储的数据
      const id = info?.user?.id;
      if (id) {
        const userData: any = await fetchUserInfo(id);
        const agencys = await fetchUserTeam(userData?.user?.id); // 当前用户所属机构
        const config = await httpProjectPatientAuthConfig({ id: userData?.employee?.organizeId }); // 当前用户所属机构
        // console.log(config);
        const userInfoData = {
          ...userData,
          agency: agencys?.[0] || {},
          agencys: agencys || [],
          projeactConfig: config,
        };
        console.log('当前用户信息');
        // console.log(userInfoData);
        const flag = userInfoData?.resourceCodes?.filter((item: any) =>
          COMBINEDWARDCODE.includes(item),
        );
        console.log('是否是联合病房医生', flag);
        setCombinedWardFlag(flag?.length > 0);
        setProjectConfig(config);
        setUserInfo(userInfoData);
      } else {
        navigate('/login');
      }
      setIsError(false);
    } catch (error: any) {
      console.log('初始化用户信息报错');
      console.log(error);
      if (error.retCode !== 403) {
        setIsError(true);
        navigate('/login');
      }
    }
    lock = false;
  };

  const fetchUserInfo = async (id: string) => {
    return httpGetIdentityById(id).then((res) => {
      return res;
    });
  };

  const fetchUserTeam = (userId: any) => {
    return getAgencyList(userId).then((data: any) => {
      return data;
    });
  };
  return [userInfo, isError, fetchData, combinedWardFlag];
};

export default useCurrentLoginUserInfo;

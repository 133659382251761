/* eslint-disable indent */
/*
 * @Description: 讲稿演示-主要内容-入院记录
 * @Author: likaifeng
 * @Date: 2023-11-30 16:29:50
 * @LastEditTime: 2025-03-27 19:13:05
 * @LastEditors: 李开锋
 */
import { useLocales } from '@/Locales';
import { speechFilterMakeDataAtom } from '@/store/lecture';
import React from 'react';
import { useRecoilValue } from 'recoil';
import SpeechCard from '../SpeechCard';
import SpeechField from '../SpeechField';
import SpeechStep from '../SpeechStep';
import SpeechStepCard from '../SpeechStepCard';
import styles from './index.less';

/**
 * @description: 讲稿演示-主要内容-入院记录
 * @author: likaifeng
 * @return {*}
 * @Date: 2023-12-04 14:24:36
 */
const SpeechAdmitted: React.FC = () => {
  const speechFilterMakeData = useRecoilValue(speechFilterMakeDataAtom);
  const { t } = useLocales();

  return speechFilterMakeData?.medicalRecord?.length ? (
    <SpeechCard titleId="medicalRecord" title="入院记录">
      <div className={styles['speech-admitted']}>
        {speechFilterMakeData?.medicalRecord?.map((item: any) => (
          <SpeechStep
            key={item.id}
            id={`medicalRecord_${item.id}`}
            time={`${t('入院时间')}：${item?.recordTime}`}
          >
            <SpeechStepCard
              padding="16px 16px 16px 16px"
              disabled={!!item.imagePath && !!item.imagePath.length}
            >
              <SpeechField
                title="报告图片"
                border={false}
                type="IMAGE"
                imagesSource={item.imagePath}
              />
            </SpeechStepCard>
            <SpeechStepCard
              disabled={
                !!item.chiefComplaint ||
                !!item.presentIllness ||
                !!item.pastHistory ||
                !!item.physicalCheck ||
                !!item.other
              }
            >
              <SpeechField border title="主诉" description={item.chiefComplaint} />
              <SpeechField border title="现病史" description={item.presentIllness} />
              <SpeechField border title="既往史" description={item.pastHistory} />
              <SpeechField border title="体格检查" description={item.physicalCheck} />
              <SpeechField border title="专科检查" description={item.specialistInspection} />
              <SpeechField border title="辅助检查" description={item.auxiliaryExamination} />
              <SpeechField border title="入院诊断" description={item.diagnosis} />
              <SpeechField title="其他" border="false" description={item.other} />
            </SpeechStepCard>
          </SpeechStep>
        ))}
      </div>
    </SpeechCard>
  ) : null;
};

export default SpeechAdmitted;

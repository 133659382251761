/* eslint no-undef: 0 */
import { apiPrefix, appName, baseURL, scope } from '@/config/base';
import { getLocales } from '@/Locales';
import { getToken, removeToken } from '@/utils/cookies';
import { message } from '@sinohealth/butterfly-ui-components/lib';
import axios, { AxiosInstance } from 'axios';
import qs from 'qs';
import { codeConfig } from './config';

// 默认配置
axios.defaults.timeout = 10 * 60 * 1000;
axios.defaults.withCredentials = false;
// @ts-ignore
axios.defaults.headers['Content-Type'] = 'application/json';

// console.log('AxiosInstance');
// @ts-ignore
// console.log(AxiosInstance);

interface requestInstance extends AxiosInstance {}
// 普通请求实例
const request: requestInstance = axios.create({
  baseURL: baseURL + apiPrefix,
  maxRedirects: 0,
});

// form-data类型请求实例
const requestFd = axios.create({
  baseURL: baseURL + apiPrefix,
  method: 'post',
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
    biz: scope,
    scope,
    app: appName,
  },
  transformRequest: [
    (data) => {
      Object.keys(data).forEach((key) => {
        if (typeof data[key] === 'object') {
          // eslint-disable-next-line no-param-reassign
          data[key] = JSON.stringify(data[key]);
        }
      });
      return qs.stringify(data);
    },
  ],
});

request.interceptors.request.use((conf: any) => conf);
function beforeRequest(options: any) {
  const newOptions: any = { ...options };
  const token = getToken();
  if (token) {
    newOptions.headers['X-Token'] = token;
  }
  newOptions.headers.biz = scope;
  newOptions.headers.scope = scope;
  newOptions.headers.app = appName;
  newOptions.headers.locales = getLocales();
  return newOptions;
}
function resolve(response: any) {
  const { data } = response;
  if (response.config.isFile) {
    return Promise.resolve(response);
  }
  if (data.retCode === 200) {
    if (response.config.isReturnAllData) {
      return Promise.resolve(data);
    }
    return Promise.resolve(data.data);
  }
  if (data.retCode === 401 && !response.config?.noAuthentication) {
    message.error(data?.retMsg || '登录状态已过期，请重新登录');
    window.location.hash = '/login';
    removeToken();
  } else if (!response.config.hiddenErrorMsg) {
    // 接口出错提示
    const codeConfigItem = data?.retMsg || codeConfig[response.data?.retCode];
    if (codeConfigItem) {
      const locales = getLocales();
      message.error(codeConfigItem[locales] || '服务器出错了，请稍后再试！');
    } else if (data.retMsg !== 'jwtToken过期!') {
      message.error(`${data.retMsg || '服务器出错了，请稍后再试！'}`);
    } else {
      message.error(`${data.retMsg || '服务器出错了，请稍后再试！'}`);
    }
  }
  return Promise.reject(data);
}
function reject(error: any) {
  let errorMessageText = error.retMsg;
  const { response } = error;
  // 检查是否是取消请求
  // eslint-disable-next-line no-underscore-dangle
  if (error?.__CANCEL__) {
    return Promise.reject(error); // 直接返回，不显示错误消息
  }
  if (response) {
    if (response.data?.retCode === 401 && !response.config?.noAuthentication) {
      // message.error('登录状态已过期，请重新登录');
      window.location.hash = '/login';
      errorMessageText = '登录状态已过期，请重新登录';
      removeToken();
    }
  } else if (response?.status === 401 && !response?.config?.noAuthentication) {
    // message.error(response?.data || response.data.retMsg || '登录状态已过期，请重新登录');
    errorMessageText = '登录状态已过期，请重新登录';
    window.location.hash = '/login';
    removeToken();
  } else if (error.request) {
    errorMessageText = error.request;
  } else if (!response?.config?.noAuthentication) {
    // message.error(`${errorMessageText || '服务器出错了，请稍后再试！'}`);
    errorMessageText = `${errorMessageText || '服务器出错了，请稍后再试！'}`;
  }
  message.error(`${errorMessageText || '服务器出错了，请稍后再试！'}`);
  if (response?.config?.isReturnAllData) {
    return Promise.reject(error);
  }
  return Promise.reject(error && error.response && error.response.data);
}
request.interceptors.request.use(beforeRequest);
request.interceptors.response.use(resolve, reject);
requestFd.interceptors.response.use(resolve, reject);

export { request, requestFd };
export default request;

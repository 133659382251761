/* eslint-disable react/no-array-index-key */
import Label from '@/components/Label';
import { useLocales } from '@/Locales';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Form, Input, Typography } from '@sinohealth/butterfly-ui-components';
import classNames from 'classnames';
import styles from './index.less';

const { Link } = Typography;

const InspectionItem = (props: any) => {
  const { t, remove, onMove, index, name, ...restField } = props;

  return (
    <div className={styles['inspection-item']}>
      <Form.Item
        noStyle
        {...restField}
        name={[name, 'itemName']}
        rules={[{ required: true, message: t('请输入指标名称') }]}
      >
        <Input placeholder={t('请输入指标名称')} />
      </Form.Item>
      <Form.Item
        noStyle
        {...restField}
        name={[name, 'resultValueItem']}
        rules={[{ required: true, message: t('请输入结果') }]}
      >
        <Input placeholder={t('请输入结果')} />
      </Form.Item>
      <Form.Item
        noStyle
        {...restField}
        name={[name, 'unit']}
        rules={[{ required: false, message: t('请输入单位') }]}
      >
        <Input placeholder={t('请输入单位')} />
      </Form.Item>
      <Form.Item
        noStyle
        {...restField}
        name={[name, 'referenceItem']}
        rules={[{ required: false, message: t('请输入参考区间') }]}
      >
        <Input placeholder={t('请输入参考区间')} />
      </Form.Item>
      <div className={styles['inspection-tool']}>
        <Link disabled={index === 0} onClick={onMove}>
          上移
        </Link>
        <Link style={{ color: '#E44444' }} onClick={() => remove(name)}>
          删除
        </Link>
      </div>
    </div>
  );
};

/**
 * @description: 检验报告
 * @author: KaifengLi
 * @version: v2.30.1
 * @Date: 2025-03-03 10:11:34
 */
const InspectTableForm = (props: any) => {
  const { name = 'zhInspectionItems', onAdd, showLabel = true } = props;

  const { t } = useLocales();

  return (
    <div className={styles['inspect-table-form']}>
      {showLabel && (
        <div style={{ marginBottom: 12 }}>
          <Label>{t('检验结果')}</Label>
        </div>
      )}
      <Form.List prefixCls="inspect-form" name={name}>
        {(fields, { add, remove, move }) => (
          <div className={classNames(styles['inspect-table'])}>
            <div className={classNames(styles['table-header'])}>
              <div className={styles['table-header-td']}>指标名称</div>
              <div className={styles['table-header-td']}>结果</div>
              <div className={styles['table-header-td']}>单位</div>
              <div className={styles['table-header-td']}>参考区间</div>
              <div className={classNames(styles['td-action'])}>操作</div>
            </div>
            <div
              className={classNames(
                styles['table-container'],
                fields.length > 0 && styles['container-padding'],
              )}
            >
              {fields.map((params: any, index: number) => (
                <InspectionItem
                  {...params}
                  t={t}
                  index={index}
                  remove={(v: any) => {
                    remove(v);
                    onAdd && onAdd();
                  }}
                  onMove={() => {
                    // 如果是第一行，则不能上移
                    if (index === 0) {
                      return;
                    }
                    // 否则将当前行与上一行交换位置
                    move(index, index - 1);
                  }}
                />
              ))}
            </div>
            <div
              style={{ borderWidth: fields.length > 0 ? 1 : 0 }}
              className={classNames(styles['table-footer'])}
            >
              <Button
                type="primary"
                ghost
                style={{ background: '#4288ff1a' }}
                onClick={() => {
                  add();
                  onAdd && onAdd();
                }}
                icon={<PlusOutlined />}
              >
                {t('加一行')}
              </Button>
            </div>
          </div>
        )}
      </Form.List>
    </div>
  );
};

export default InspectTableForm;

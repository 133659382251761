import { Form, FormItem } from '@/components/BaseForm';
import Label from '@/components/Label';
import Text from '@/components/Text';
import { useLocales } from '@/Locales';
import { PatientInfoAtom, PatientInfoStatus } from '@/pages/patient/detail';
import DicomLink from '@/pages/patient/detail/components/DicomLink';
import DicomUpload from '@/pages/patient/detail/components/DicomUpload';
import RecordImgUpload from '@/pages/patient/detail/components/RecordImgUpload';
import TabHeader, { TabHeaderInput } from '@/pages/patient/detail/components/TabHeader';
import { useInitOcrRes } from '@/pages/patient/hooks/useOcr';
import { addPatientImages, updatePatientImages } from '@/services/patient';
import { Form as AntdForm, Button, Input, Select } from '@sinohealth/butterfly-ui-components';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

const requiredRule = { required: true, message: '该字段为必填项' };

const ImageReportsTab = (props: any) => {
  const { data } = props;
  const [form] = AntdForm.useForm();
  const [readOnly, setReadOnly] = useState(false);
  const patientInfo = useRecoilValue(PatientInfoAtom);
  const [patientInfoStatus, setPatientInfoStatus] = useRecoilState(PatientInfoStatus);
  const { t } = useLocales();

  useEffect(() => {
    setReadOnly(!!data?.id);
  }, []);

  const [isEditing, setIsEditing] = useState(false);
  useEffect(() => {
    return () => {
      // 组件销毁时，如果是编辑状态，把全局的可编辑状态还原
      if (isEditing) {
        setPatientInfoStatus({
          edit: true,
        });
      }
    };
  }, [isEditing]);

  /** 患者详情图片智能识别结果回填处理 */
  const { handleSecondComfirm, saveLoading, originFormValues, setSaveLoading, recognitTaskId } =
    useInitOcrRes({
      form,
      isEditing: readOnly,
      reportType: 'imageReport',
    });

  const handleChangeReadOnly = () => {
    const newReadOnly = !readOnly;
    setReadOnly(newReadOnly);
    setPatientInfoStatus({
      edit: newReadOnly,
    });
    setIsEditing(!newReadOnly);
    if (newReadOnly) {
      patientInfo.update();
    }
    form.resetFields();
  };

  const handleCancel = () => {
    patientInfo.update();
    handleChangeReadOnly();
  };

  const handleSubmit = () => {
    form
      .validateFields()
      .then((formValue) => {
        const params = {
          ...originFormValues,
          ...formValue,
          patientId: patientInfo.patient.id,
        };
        setSaveLoading(true);
        const api = params.id ? updatePatientImages : addPatientImages;
        if (!formValue?.id) {
          params.recognitionTaskId = recognitTaskId;
        }
        api(params)
          .then(() => {
            patientInfo.update();
            handleChangeReadOnly();
          })
          .finally(() => {
            setSaveLoading(false);
          });
      })
      .catch(() => {});
  };
  const action = readOnly ? (
    <a onClick={handleChangeReadOnly}>{t('编辑')}</a>
  ) : (
    <>
      <Button
        type="link"
        style={{ padding: 0 }}
        disabled={saveLoading}
        onClick={() => handleSecondComfirm(handleChangeReadOnly, !data?.id)}
      >
        <Text>取消</Text>
      </Button>
      <Button
        type="link"
        style={{ padding: 0 }}
        disabled={saveLoading}
        onClick={() => handleSecondComfirm(handleSubmit)}
      >
        <Text>保存</Text>
      </Button>
    </>
  );
  const mapImage = (urls: string) => {
    return <RecordImgUpload originUrl={urls} label={t('报告图片/视频')} value={urls} disabled />;
  };

  return (
    <Form
      className="record-form"
      form={form}
      readOnly={readOnly}
      initialValues={data}
      layout={readOnly ? 'horizontal' : 'vertical'}
    >
      <FormItem name="id" hidden>
        <Input />
      </FormItem>
      <FormItem
        style={{ padding: 0 }}
        transformText={(text) => (
          <TabHeader
            label={t('检查时间')}
            title={text ? moment(text).format('YYYY-MM-DD') : ''}
            action={action}
          />
        )}
        name="recordTime"
        span={24}
        rules={[requiredRule]}
      >
        <TabHeaderInput label={t('检查时间')} action={action} />
      </FormItem>
      <FormItem name="reportImage" transformText={mapImage}>
        <RecordImgUpload
          label={t('报告图片/视频')}
          recordId={data?.id}
          originUrl={data.reportImage}
          accept={['image/jpeg,image/jpg,image/bmp,image/png', '.mp4'].join(',')}
          fileType="imageReport"
        />
      </FormItem>
      <FormItem label={<Label>影像类型</Label>} name="imagesType" dictKey="imageType">
        <Select />
      </FormItem>
      <FormItem label={<Label>检查部位</Label>} name="parts">
        <Input.TextArea autoSize={{ minRows: 4, maxRows: 4 }} maxLength={200} showCount />
      </FormItem>
      <FormItem
        label={<Label>DICOM</Label>}
        name="storeUid"
        transformText={(v) => <DicomUpload disabled value={v} id={data.id} />}
      >
        <DicomUpload id={data.id} />
      </FormItem>
      <FormItem
        label={<Label>DICOM预览链接</Label>}
        name="dicomQrcodeUrl"
        transformText={(text) => {
          return text ? (
            <a href={text} target="_blank" rel="noreferrer">
              {text}
            </a>
          ) : (
            '--'
          );
        }}
      >
        <DicomLink />
      </FormItem>
      <FormItem label={<Label>检查所见</Label>} name="reportDescription">
        <Input.TextArea maxLength={1000} showCount autoSize={{ minRows: 4, maxRows: 4 }} />
      </FormItem>
      <FormItem label={<Label>检查结论</Label>} name="reportDiagnose">
        <Input.TextArea maxLength={1000} showCount autoSize={{ minRows: 4, maxRows: 4 }} />
      </FormItem>
      <FormItem
        label={
          <>
            <Label>备注</Label>
            {!readOnly && (
              <span style={{ fontSize: '14px', color: '#00000073' }}>
                （{t('备注内容将显示在讲稿左侧目录')}）
              </span>
            )}
          </>
        }
        name="remarks"
      >
        <Input maxLength={20} showCount />
      </FormItem>
    </Form>
  );
};

export default ImageReportsTab;

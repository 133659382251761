import { Form, FormItem } from '@/components/BaseForm';
import Label from '@/components/Label';
import { PatientInfoAtom, PatientInfoStatus } from '@/pages/patient/detail';
import {
  Form as AntdForm,
  Col,
  Input,
  Row,
  Select,
  Space,
} from '@sinohealth/butterfly-ui-components';
import { useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';

import PatientTag from '@/components/PatientTag';
import RichTextRender from '@/components/RichTextRender';
import TumorStageSelect from '@/components/TumorStageSelect';
import { useLocales } from '@/Locales';
import DoctorItem from '@/pages/patient/detail/components/PatientInfo/components/DoctorItem';
import { maskMiddleCharacters, transformTumorStage } from '@/utils';
import { CaretDownOutlined, EditOutlined } from '@ant-design/icons';
import styles from './index.less';

const PatientInfo = (props: any) => {
  const { defaultOpenSummary = false } = props;
  const [isEdit, setIsEdit] = useState(false);
  const [isOpenSummary, setIsOpenSummary] = useState(defaultOpenSummary);
  const [patientInfoStatus, setPatientInfoStatus] = useRecoilState(PatientInfoStatus);
  const patientInfo = useRecoilValue(PatientInfoAtom);
  const [form] = AntdForm.useForm();
  const { t } = useLocales();
  const [params] = useSearchParams();
  const isShowLabel = params.get('type') === 'cl'; // 是否显示病例标签和主治医生
  const isShare = params.get('type') === 'share'; // 是否显示病例标签和主治医生

  const handleTagChange = () => {
    patientInfo.update();
  };

  const handleMakeSpeech = () => {
    const query = `patientId=${patientInfo?.patient?.id}&patientName=${patientInfo?.patient?.name}`;
    window.open(`${window.location.pathname}#/speech-lecture/lecture?${query}`);
  };

  return (
    <div className={styles.patientInfo}>
      <div className="but-title">
        {t('患者信息')}
        <Space className={styles.action} split={<span style={{ color: '#97979759' }}>|</span>}>
          {patientInfoStatus.edit && (
            <Link to={`/patient/detail/patientInfoEdit?id=${patientInfo.patient.id}`}>
              <EditOutlined /> {t('编辑')}
            </Link>
          )}
          {/* <a onClick={handleMakeSpeech}>
            <EyeOutlined /> {t('预览讲稿')}
          </a> */}
          <Link to={`/patient/detail/patientInfoDetail?id=${patientInfo.patient.id}`}>
            {t('更多基本信息')}
          </Link>
          {/* {isEdit ? ( */}
          {/*  <> */}
          {/*    <a onClick={handleCancelEdit}>取消</a> */}
          {/*    <a onClick={handleSubmit}>保存</a> */}
          {/*  </> */}
          {/* ) : ( */}
          {/*  patientInfoStatus.edit && ( */}
          {/*    <a onClick={handleChangeEditStatus}> */}
          {/*      <EditOutlined /> 编辑 */}
          {/*    </a> */}
          {/*  ) */}
          {/* )} */}
        </Space>
      </div>
      <Form
        form={form}
        requiredMark={false}
        readOnly={!isEdit}
        initialValues={patientInfo?.patient}
      >
        <div className={styles.content}>
          <Row gutter={24}>
            <FormItem name="id" hidden={true} noStyle span={0}>
              <Input />
            </FormItem>
            <FormItem
              label={<Label>姓名</Label>}
              name="name"
              span={4}
              transformText={(text) => maskMiddleCharacters(text) || '--'}
              rules={[{ required: true, message: t('请填写患者姓名') }]}
            >
              <Input />
            </FormItem>
            <FormItem label={<Label>性别</Label>} name="gender" dictKey="gender" span={4}>
              <Select />
            </FormItem>
            <FormItem
              label={<Label>年龄</Label>}
              name="age"
              transformText={(text) => text || '--'}
              span={4}
            >
              <Input />
            </FormItem>
            {/* <FormItem */}
            {/*  label={<Label>身份证号</Label>} */}
            {/*  name="idCard" */}
            {/*  span={5} */}
            {/*  rules={[{ pattern: idCardReg, message: t('身份证格式有误') }]} */}
            {/* > */}
            {/*  <Input /> */}
            {/* </FormItem> */}
            <FormItem label={<Label>医院</Label>} name="hospitalName" span={6}>
              <Input />
            </FormItem>
            <FormItem
              label={<Label>最新肿瘤分期</Label>}
              name="tumorStage"
              transformText={transformTumorStage}
              span={6}
            >
              <TumorStageSelect />
            </FormItem>
            {(isShowLabel || isShare) && !isEdit && (
              <Col span={24}>
                <Row gutter={24} style={{ borderTop: '1px dashed #97979759', padding: '15px 0' }}>
                  {(isShare || isShowLabel) && (
                    <Col span={6}>
                      <Label style={{ marginRight: 10 }}>{t('主治医生')}</Label>
                      <DoctorItem doctorId={patientInfo.patient.doctorId} />
                    </Col>
                  )}
                  {isShowLabel && (
                    <Col span={18}>
                      <PatientTag
                        onChange={handleTagChange}
                        tags={patientInfo.zhPatientLabelList}
                        patientId={patientInfo.patient.id}
                      />
                    </Col>
                  )}
                </Row>
              </Col>
            )}
            <FormItem
              label={<Label>最新诊断</Label>}
              name="latestDiagnosis"
              style={{ borderTop: '1px dashed #97979759' }}
              span={24}
            >
              <Input maxLength={100} />
            </FormItem>
          </Row>
        </div>
        {/* 隐藏 */}
        {false && (
          <div className="but-title" style={{ margin: '20px 0 0 -16px' }}>
            {t('病史摘要')}
            <Space className={styles.action} split={<span style={{ color: '#97979759' }}>|</span>}>
              {/* {!isEdit && patientInfoStatus.edit ? (
              <span>
                <Link to={`patientSummary?id=${patientInfo?.patient.id}`}>
                  <EditOutlined /> {t('编辑摘要')}
                </Link>
              </span>
            ) : null} */}
              <a onClick={() => setIsOpenSummary(!isOpenSummary)}>
                {isOpenSummary ? t('收起摘要') : t('展开摘要')}
                <CaretDownOutlined
                  style={{ transform: isOpenSummary ? 'rotate(180deg)' : 'none' }}
                />
              </a>
            </Space>
          </div>
        )}
        {isOpenSummary && (
          <div className={styles.content} style={{ padding: '16px', marginTop: 16 }}>
            <RichTextRender content={patientInfo?.patient.history} />
          </div>
        )}
        {/* <FormItem hidden={!isOpenSummary && !isEdit} name="history" span={24} noStyle transformText={(v) => }> */}
        {/*  <PatientSummary /> */}
        {/* </FormItem> */}
      </Form>
    </div>
  );
};

export default PatientInfo;

import Label from '@/components/Label';
import useDict from '@/hooks/dict/useDict';
import { useLocales } from '@/Locales';
import { getUuid } from '@/utils';
import { Table } from '@sinohealth/butterfly-ui-components';

export const StatusColor: any = {
  0: 'rgba(0, 0, 0, 0.88)',
  1: '#F53F3F',
  2: '#40BA32',
  3: '#F53F3F',
};

export const StatusTips: any = {
  null: '-',
  0: '-',
  1: '',
  2: '↓',
  3: '↑',
};

const InspectionItemsTable = (props: any) => {
  const { data = [], scroll, showLabel = true } = props;
  const tumorMarker = useDict('tumorMarker');
  const { t } = useLocales();
  const columns: any = [
    // {
    //   title: '检验项目',
    //   dataIndex: 'type',
    //   key: 'type',
    //   render(text: string) {
    //     return text === '0' ? t('肿瘤标志物') : t('其他');
    //   },
    // },
    {
      title: '指标名称',
      dataIndex: 'itemName',
      key: 'itemName',
      align: 'left',
      render(text: string, record: any) {
        return <span style={{ color: StatusColor?.[record.status] }}>{text}</span>;
      },
    },
    {
      title: '结果',
      dataIndex: 'resultValueItem',
      key: 'resultValueItem',
      align: 'left',
      render(text: string, record: any) {
        return <span style={{ color: StatusColor?.[record.status] }}>{text}</span>;
      },
    },
    {
      title: '提示',
      dataIndex: 'resultValueItem',
      key: 'resultValueItem',
      align: 'left',
      render(text: string, record: any) {
        return (
          <span style={{ color: StatusColor?.[record.status] }}>
            {StatusTips[record.status] || text}
          </span>
        );
      },
    },
    {
      title: '单位',
      dataIndex: 'unit',
      key: 'unit',
      align: 'left',
      render(text: string, record: any) {
        return <span style={{ color: StatusColor?.[record.status] }}>{text}</span>;
      },
    },
    {
      title: '参考区间',
      dataIndex: 'referenceItem',
      key: 'referenceItem',
      align: 'right',
      render(text: string, record: any) {
        return <span style={{ color: StatusColor?.[record.status] }}>{text}</span>;
      },
    },
  ].map((item) => {
    return {
      ...item,
      title: t(item.title),
    };
  });
  return (
    <div>
      {showLabel && (
        <div style={{ marginBottom: 12 }}>
          <Label>检验结果</Label>
          {data.length === 0 && <span style={{ marginLeft: 12 }}>--</span>}
        </div>
      )}
      {data.length > 0 && (
        <Table
          columns={columns}
          scroll={scroll}
          rowKey={() => getUuid()}
          dataSource={data}
          pagination={false}
        />
      )}
    </div>
  );
};

export default InspectionItemsTable;

import { atom } from 'recoil';

export const userInfoAtom = atom<any>({
  key: 'userInfo',
  default: null,
});

/** 联合病房判断 */
export const combinedWardFlagAtom = atom<any>({
  key: 'combinedWardFlag',
  default: false,
});

/** 项目是否配置了患者授权 */
export const projectConfigAtom = atom<any>({
  key: 'projectConfig',
  default: null,
});

export default {};

import Image from '@/components/Image';
import ImageEditorModal from '@/components/ImageEditorModal';
import { useLocales } from '@/Locales';
import { OcrContext } from '@/pages/patient/detail/components/PatientTabs/components/TabItem';
import { isVideoFile } from '@/utils';
import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleFilled,
  EyeOutlined,
} from '@ant-design/icons';
import { Modal, Space } from '@sinohealth/butterfly-ui-components';
import classnames from 'classnames';
import { useContext, useRef, useState } from 'react';
import styles from './index.less';

const OcrImage = (props: any) => {
  // onImageUrl: (imgUrl: string) => void; // 获取图片地址
  // onPreview: () => void; // 预览图片
  const { onDelete, onUpdate, index = 0, onEdit, onImageUrl, onPreview, id, ocr, ...other } = props;
  const [imgData, setImageData] = useState('');
  const { ocrConfig, setOcrConfig } = useContext(OcrContext);
  const imageEditorModalRef = useRef<any>(null);
  const { t } = useLocales();
  const handleOnOcr = (e: any) => {
    e.stopPropagation();
    setOcrConfig({
      open: true,
      data: imgData,
    });
  };
  const handleDelete = (e: any) => {
    e.stopPropagation();
    Modal.confirm({
      title: t('删除图片'),
      centered: true,
      icon: <ExclamationCircleFilled className="confirm_danger_icon_color" />,
      content: t('是否确定移除该图片？'),
      okButtonProps: {
        danger: true,
      },
      onOk: () => {
        onDelete && onDelete(props.src);
      },
    });
  };

  const handleChange = (newId: string) => {
    props?.onEdit && props?.onEdit(false);
    onUpdate && onUpdate(newId);
  };

  // 图片下载完成回调
  const onLoadedImgData = (imgUrl: string) => {
    setImageData(imgUrl);
    onImageUrl && onImageUrl(imgUrl);
  };

  const handleEditImage = (e: any) => {
    e.stopPropagation();
    props?.onEdit && props?.onEdit(true);
    imageEditorModalRef.current.openModal({
      src: props.src,
    });
  };
  // 通过文件格式判断是否为视频
  const isVideo = isVideoFile(props.src);

  const preview = {
    mask: (
      <div
        className={styles.previewBox}
        onClick={(e) => {
          onPreview && e.stopPropagation();
        }}
      >
        <div className={styles.actions}>
          <Space style={{ zIndex: 99 }} size={16}>
            <EyeOutlined
              onClick={() => {
                onPreview && onPreview();
              }}
            />
            {!props.disabled && (
              <>
                {onEdit && <EditOutlined onClick={handleEditImage} />}
                <DeleteOutlined onClick={handleDelete} />
              </>
            )}
          </Space>
        </div>
        {ocr && (
          <div className={styles.ocrBtn} onClick={handleOnOcr}>
            {t('OCR识别')}
          </div>
        )}
      </div>
    ),
  };

  const classNames = classnames({
    [styles.img]: true,
    [styles.ocrIng]: ocrConfig?.data === imgData,
  });

  return (
    <div className={classNames} id={id}>
      {isVideo && !props.disabled && (
        <div className={styles.videoAction}>
          <DeleteOutlined className={styles.btn} onClick={handleDelete} />
        </div>
      )}
      {!props.disabled ? <div className={styles['index-number']}>{index}</div> : null}
      <Image
        key={props.src}
        isVideo={isVideo}
        onLoadedImgData={onLoadedImgData}
        preview={preview}
        {...other}
      />
      {ocrConfig?.data === imgData && <div className={styles.ocrRes}>{t('OCR识别完成')}</div>}
      <ImageEditorModal ref={imageEditorModalRef} onOk={handleChange} onCancel={props?.onEdit} />
    </div>
  );
};

export default OcrImage;

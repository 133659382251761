/* eslint-disable prefer-promise-reject-errors */
import { sendCodeTimeKey } from '@/config/base';
import { COMBINEDWARDCODE } from '@/hooks/useCurrentLoginUserInfo';
import { useLocales } from '@/Locales';
import { getAgencyList } from '@/services/common';
import { httpProjectPatientAuthConfig } from '@/services/patient';
import { getVerifyCode, httpGetDefaultAgecny, httpGetIdentityById, httpLogin, httpSystemSetting } from '@/services/user';
import { combinedWardFlagAtom, projectConfigAtom, userInfoAtom } from '@/store/atom';
import { validIsMobile } from '@/utils';
import {
  clearSystemUserInfo,
  removeToken,
  setLocalStorage,
  setSystemUserInfo,
  setToken,
} from '@/utils/cookies';
import {
  Button,
  Checkbox,
  Col,
  Form,
  FormInstance,
  Input,
  Row,
  message,
} from '@sinohealth/butterfly-ui-components';
import classnames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';
import styles from './index.less';

/**
 * @description: 确认按钮
 * @author: likaifeng
 * @param {object} param1
 * @return {*}
 * @Date: 2023-11-17 10:35:11
 */
const SubmitButton = ({ form }: { form: FormInstance }) => {
  const navigate = useNavigate();
  const [submittable, setSubmittable] = useState(false);
  const [loginLoading, setLoginLoading] = useState(false);
  const setUserInfo = useSetRecoilState(userInfoAtom);
  // 是否是联合病房医生
  const setCombinedWardFlag = useSetRecoilState(combinedWardFlagAtom);
  // 项目配置
  const setProjectConfig = useSetRecoilState(projectConfigAtom);

  const { t } = useLocales();

  /**
   * @description: 监听 From
   * @author: likaifeng
   * @return {*}
   * @Date: 2023-11-17 10:35:33
   */
  const values = Form.useWatch([], form);

  /**
   * @description: 登录请求
   * @author: likaifeng
   * @return {*}
   * @Date: 2023-11-17 11:05:04
   */
  const handleSumbitForm = async () => {
    try {
      // eslint-disable-next-line no-shadow
      const values: any = await form.validateFields();
      console.log('Success:', values);
      const { password, username, phone, captcha } = values;
      setLoginLoading(true);
      httpLoginReq({ password, username, phone, captcha });
    } catch (errorInfo) {
      console.log('Failed:', errorInfo);
      setLoginLoading(false);
    }
  };

  /**
   * @description: 登录接口请求
   * @author: likaifeng
   * @param {any} params
   * @return {*}
   * @Date: 2023-11-17 11:05:16
   */
  const httpLoginReq = async (params: any) => {
    try {
      const res: any = await httpLogin(params);
      // console.log(res);
      if (res.status === '0') {
        message.error('该账号已被禁用, 请联系管理员!');
        setLoginLoading(false);
        return;
      }
      if (res.token) {
        setToken(res.token);
        httpGetIdentityByIdReq(res.id);
        setLoginLoading(false);
      } else {
        setLoginLoading(false);
      }
    } catch (err: any) {
      console.log(err);
      message.error(err.retMsg || '服务器出错了，请稍后再试！');
      setLoginLoading(false);
    }
  };

  /**
   * @description: 获取系统管理-用户基础信息表信息成功
   * @author: likaifeng
   * @param {any} id 用户id
   * @return {*}
   * @Date: 2023-11-17 11:40:32
   */
  const httpGetIdentityByIdReq = async (id: any) => {
    const userData: any = await httpGetIdentityById(id);
    const agencys = await fetchUserTeam(userData?.user?.id); // 当前用户所属机构
    const config = await httpProjectPatientAuthConfig({ id: userData?.employee?.organizeId }); // 当前用户所属机构
    const userInfoData = {
      ...userData,
      agency: agencys?.[0] || {},
      agencys: agencys || [],
      projeactConfig: config,
    };
    // console.log('当前用户信息');
    // console.log(userInfoData);
    const flag = userInfoData?.resourceCodes?.filter((item: any) =>
      COMBINEDWARDCODE.includes(item),
    );
    // console.log('是否是联合病房医生', flag);
    setCombinedWardFlag(flag?.length > 0);
    setProjectConfig(config);
    setUserInfo(userInfoData);
    setSystemUserInfo({ ...userData, projeactConfig: config });
    // httpGetDefaultAgecnyReq(res.user.id);
    navigate('/');
  };

  const fetchUserTeam = (userId: any) => {
    return getAgencyList(userId).then((data: any) => {
      return data;
    });
  };

  /**  默认机构【医助】 - 查询医生详情（单个） */
  const httpGetDefaultAgecnyReq = async (id: string) => {
    try {
      const res: any = await httpGetDefaultAgecny(id);
      if (res.id) {
        setLocalStorage('parentId', res.id);
      }
    } catch (err) {
      console.log(err);
    }
  };

  React.useEffect(() => {
    let isMounted = true; // 添加一个标志来追踪组件是否已卸载
    try {
      form.validateFields().then(
        () => {
          if (isMounted) setSubmittable(true);
        },
        () => {
          if (isMounted) setSubmittable(false);
        },
      );
    } catch (err) {
      console.log(err);
    }

    // 在清理函数中取消异步操作和订阅
    return () => {
      isMounted = false;
    };
  }, [values]);

  return (
    <Button
      className={styles['submit-btn']}
      block
      size="large"
      type="primary"
      htmlType="submit"
      loading={loginLoading}
      disabled={!submittable}
      onClick={handleSumbitForm}
    >
      {t('登录')}
    </Button>
  );
};

const sendCodeTimeOut = 60;

/**
 * @description: 登录页面
 * @author: likaifeng
 * @return {*}
 * @Date: 2023-11-16 16:52:00
 */
const Login: React.FC = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [, setUserInfo] = useRecoilState(userInfoAtom);
  const { t, changeLocales, locales } = useLocales();

  const [phoneNo, setPhoneNo] = useState('');
  const [canSendCode, setCanSendCode] = useState(true);
  const [timeLeft, setTimeLeft] = useState(0);
  const [enableMFA, setEnableMFA] = useState(false);
  const [loadingSetting, setLoadingSetting] = useState(false);

  useEffect(() => {
    // clearLocalStorage();
    clearSystemUserInfo();
    httpSystemSettingReq();
    removeToken();
    setUserInfo(null); // 进入登录页就清空全局状态的用户信息
  }, []);

  /**
   * @description: 捕获Form的错误信息
   * @author: likaifeng
   * @return {*}
   * @Date: 2023-11-17 11:31:26
   */
  const getErrorMessage = () => {
    const errors = form.getFieldsError();
    let errorMessage: string[] = [];
    errors.forEach((item) => {
      errorMessage = errorMessage.concat(item.errors);
    });
    const errorMsg = errorMessage.reverse().pop();
    errorMsg && message.error(errorMsg);
  };

  /**
   * @description: 校验登录账号
   * @author: likaifeng
   * @param {any} rule
   * @param {any} value
   * @return {*}
   * @Date: 2023-11-17 10:34:20
   */
  const validPhoneAccount = (rule: any, value: any) => {
    if (value === '') {
      return Promise.reject(new Error(t('登录账号不能为空')));
    }
    return Promise.resolve();
  };

  const validPhoneNo = (rule: any, value: any) => {
    if (value === '') {
      return Promise.reject('手机号码不能为空');
    }
    if (!validIsMobile(value)) {
      return Promise.reject('手机号格式不正确');
    }
    return Promise.resolve();
  };

  const handleSendSmsCode = async () => {
    if (!phoneNo) {
      message.error('手机号码不能为空');
      return;
    }
    try {
      await getVerifyCode(phoneNo);
      setLocalStorage(sendCodeTimeKey, Date.now());
      setTimeLeft(sendCodeTimeOut);
      setCanSendCode(false);
    } finally {
      console.log(1);
    }
  };

  /** 获取登录配置 */
  const httpSystemSettingReq = async () => {
    try {
      setLoadingSetting(true);
      const res: any = await httpSystemSetting();
      // console.log(res);
      setEnableMFA(res.enableMFA || false);
    } catch (err) {
      console.log(ErrorEvent);
    } finally {
      setLoadingSetting(false);
    }
  };

  useEffect(() => {
    if (timeLeft > 0) {
      setCanSendCode(false);
      setTimeout(() => {
        setTimeLeft(timeLeft - 1);
      }, 900);
    } else {
      setCanSendCode(true);
    }
  }, [timeLeft]);

  /**
   * @description: 校验用户协议
   * @author: likaifeng
   * @param {any} rule
   * @param {any} val
   * @return {*}
   * @Date: 2023-11-17 10:34:45
   */
  const agreementValid = (rule: any, val: any) => {
    return val ? Promise.resolve() : Promise.reject();
  };

  const logoClassName = classnames({
    [styles['login-title']]: true,
    [styles['login-title-en']]: locales === 'en',
  });

  return (
    // <div className={styles['login-container']}>
    <Row className={styles['login-container']}>
      <Col style={{ height: '100%' }} xxl={16} xl={15} lg={12} md={12}>
        <div className={styles['login-bg']} />
      </Col>
      <Col style={{ height: '100%' }} xxl={8} xl={9} lg={12} md={12}>
        <div className={styles.container}>
          <div className={logoClassName} />
          <div className={styles['login-form']}>
            <div className={styles['form-title']}>{t('用户登录')}</div>
            <Form
              form={form}
              onFinishFailed={getErrorMessage}
              disabled={loadingSetting}
              onKeyPress={(e) => {
                if (e.key.toLowerCase() === 'enter') {
                  form.submit();
                }
              }}
            >
              <Form.Item
                noStyle
                name="username"
                rules={[{ required: true, validator: validPhoneAccount }]}
              >
                <Input
                  className={styles['form-input']}
                  size="large"
                  placeholder={t('请输入账户名')}
                  prefix={<span className="form-icon iconfont icon-tianchongxing-4" />}
                />
              </Form.Item>
              <Form.Item
                noStyle
                name="password"
                rules={[{ required: true, message: t('登录密码不能为空') }]}
              >
                <Input.Password
                  className={styles['form-input']}
                  size="large"
                  placeholder={t('请输入密码')}
                  prefix={<span className="form-icon iconfont icon-tianchongxing-17" />}
                />
              </Form.Item>
              {
                enableMFA ?
                  <>
                    <Form.Item
                      noStyle
                      name="phone"
                      rules={[{ required: true, validator: validPhoneNo }]}
                    >
                      <Input
                        className={styles['form-input']}
                        size="large"
                        placeholder={t('请输入注册手机号')}
                        prefix={<span className="form-icon iconfont icon-phone" />}
                        onChange={(e) => setPhoneNo(e.target.value)}
                      />
                    </Form.Item>
                    <Form.Item
                      noStyle
                      name="captcha"
                      rules={[{ required: true, message: '验证码不能为空' }]}
                    >
                      <Input
                        className={styles['form-input']}
                        size="large"
                        placeholder={t('请输入短信验证码')}
                        prefix={<span className="form-icon iconfont icon-tianchongxing-2" />}
                        suffix={
                          <Button
                            type="link"
                            size="small"
                            style={{ fontSize: 16 }}
                            disabled={!canSendCode || !validIsMobile(phoneNo)}
                            onClick={() => handleSendSmsCode()}
                          >
                            {canSendCode ? '获取验证码' : `${timeLeft}s后重新获取`}
                          </Button>
                        }
                      />
                    </Form.Item>
                  </>
                  : null
              }
              <Form.Item noStyle>
                <Form.Item
                  noStyle
                  name="remember"
                  valuePropName="checked"
                  initialValue={false}
                  rules={[
                    {
                      validator: agreementValid,
                      message: t('请先阅读勾选《用户服务协议》《隐私政策》'),
                    },
                  ]}
                >
                  <Checkbox className={styles['form-check']}>{t('已阅读并同意')}</Checkbox>
                </Form.Item>
                <span
                  className={styles.protocol}
                  // onClick={() => navigate('/login/user-protocol?id=1')}
                  onClick={() =>
                    window.open(`${window.location.pathname}#/login/user-protocol?id=1`, '_blank')
                  }
                >
                  {t('《用户服务协议》')}
                </span>
                <span
                  className={styles.protocol}
                  // onClick={() => navigate('/login/user-protocol?id=2')}
                  onClick={() =>
                    window.open(`${window.location.pathname}#/login/user-protocol?id=2`, '_blank')
                  }
                >
                  {t('《隐私政策》')}
                </span>
              </Form.Item>
              <Form.Item style={{ marginTop: 20 }}>
                <SubmitButton form={form} />
              </Form.Item>
            </Form>
            <div className={styles.locales}>
              <span onClick={() => changeLocales('zh-cn')}>中文</span> ｜{' '}
              <span onClick={() => changeLocales('en')}>English</span>
            </div>
          </div>
          <div className={styles.copyright}>
            <div>Copyright © 2023 {t('广州中惠医疗科技有限公司')}.</div>
            <div>All rights reserved.</div>
          </div>
        </div>
      </Col>
    </Row>
    // </div>
  );
};

export default Login;

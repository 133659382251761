/* eslint-disable indent */
import { useLocales } from '@/Locales';
import { previewFile } from '@/utils';
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  LoadingOutlined,
  ZoomInOutlined,
} from '@ant-design/icons';
import { Image as Img, Space, theme } from '@sinohealth/butterfly-ui-components';
import classNames from 'classnames';
import React, { lazy, Suspense, useCallback, useMemo, useRef, useState } from 'react';
import { ImageDecorator } from 'react-viewer/lib/ViewerProps';
import styles from './index.less';

const CompressImageEditor = lazy(() => import('../CompressImageEditor'));
const ReactViewer = lazy(() => import('@/components/ReactViewer'));
const ImageConfirmDialog = lazy(() => import('../ImageConfirmDialog'));

interface ImageMaskProps {
  id?: string; // 任务id
  fileType?: string; // 图片类型
  sort?: number; // 图片排序
  recordTime?: string; // 图片日期
  className?: string; // 自定义类名
  loading?: boolean; // 图片检测中
  blob?: boolean; // 是否为blob格式
  progress: number; // 进度
  picSource?: string[]; // 用于预览的图片数据集合
  index: number; // 当前图片的索引
  status?: string; // 图片状态
  tip?: string; // 提示信息
  error?: boolean; // 是否为错误状态
  url?: string; // 图片地址
  preview?: boolean; // 是否预览
  edit?: boolean; // 是否可编辑
  deleted?: boolean; // 是否可删除
  onDelete?: () => void; // 删除
  onOk?: () => void; // 确认日期
}

const { useToken } = theme;

const timeIcon = require('@/assets/images/common/time_icon.png');

const loadAndResolveImage = (src: string): Promise<string> => {
  return new Promise((resolve) => {
    const img = new Image();
    img.src = previewFile(src);
    img.onload = () => {
      resolve(previewFile(src));
    };
    img.onerror = () => {
      resolve(timeIcon);
    };
  });
};

const CompressImage: React.FC<ImageMaskProps> = (props) => {
  const { token } = useToken();
  const { t } = useLocales();
  const {
    id,
    fileType,
    sort,
    recordTime,
    className,
    status,
    progress = 0,
    loading,
    url,
    tip,
    error,
    blob,
    picSource = [],
    index = 0,
    preview = true,
    deleted = true,
    edit = true,
    onDelete,
    onOk,
  } = props;

  const imageEditorModalRef = useRef<any>(null);
  const imageConfirmDialogRef = useRef<any>(null);
  const [viewImages, setViewImages] = useState<ImageDecorator[]>([]);
  const [viewImagesIndex, setViewImagesIndex] = useState(0);
  const memoPicSource = useMemo(() => {
    return picSource.map((el) => ({ src: blob ? el : previewFile(el) }));
  }, [picSource]);

  // 缓存 previewFile 的结果
  const memoUrl = useMemo(() => {
    return blob ? url : previewFile(url || '');
  }, [url, blob]);

  /** 预览图片 */
  const handlePreviewImages = useCallback(() => {
    if (!memoPicSource.length) return;
    setViewImages(memoPicSource);
    setViewImagesIndex(index);
  }, [memoPicSource, blob, index]);

  /** 编辑图片 */
  const handleEditImage = useCallback(() => {
    imageEditorModalRef.current.openModal({ memoUrl });
  }, [url]);

  const PreviewMask = () => {
    return (
      <div
        style={{
          textAlign: 'center',
          height: '100%',
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'absolute',
          zIndex: 99,
        }}
        onClick={(e) => e.stopPropagation()}
      >
        <Space style={{ zIndex: 99, position: 'absolute' }} size={16}>
          {loading && <LoadingOutlined />}
          {preview && !loading && <ZoomInOutlined onClick={handlePreviewImages} />}
          {edit && !blob && <EditOutlined onClick={handleEditImage} />}
          {deleted && !loading && <DeleteOutlined onClick={onDelete} />}
        </Space>
      </div>
    );
  };

  const STATUS_ICONS: any = {
    fail: (
      <CloseCircleOutlined
        style={{ color: token.colorWhite, backgroundColor: token.colorError, borderRadius: '50%' }}
      />
    ),
    completed: (
      <CheckCircleOutlined
        style={{ color: token.colorWhite, backgroundColor: '#67C23A', borderRadius: '50%' }}
      />
    ),
    pendingConfirm: <div className={styles.wait}>{t('待确认')}</div>,
    deleted: <div className={styles.wait}>{t('已删除')}</div>,
  };

  const renderStatusIcon = () => {
    return STATUS_ICONS[status || ''];
  };

  // 默认返回一个空的 div，确保不会返回 undefined
  return (
    <div className={classNames(className, styles['compress-image'])}>
      <div className={styles['compress-progress']}>
        <Space>
          {progress >= 0 &&
          status &&
          ['new', 'parsing', 'ocrCompleted', 'classifyCompleted', 'structuralCompleted'].includes(
            status,
          ) ? (
            <div className={styles.progress}>{`${progress}%`}</div>
          ) : (
            ''
          )}
          {renderStatusIcon()}
        </Space>
      </div>
      <Img
        className={styles['image-card']}
        key={memoUrl}
        src={memoUrl}
        preview={{
          maskClassName: classNames(styles.mask, loading && styles['mask-visibility']),
          mask: PreviewMask(),
        }}
        loading="lazy"
      />
      {tip && (
        <div
          className={classNames(styles['compress-tip'], error && styles['error-tip'])}
          onClick={() =>
            onOk &&
            imageConfirmDialogRef?.current?.open({
              id,
              fileType,
              recordTime,
              sort,
            })
          }
        >
          {t(tip)}
        </div>
      )}
      <Suspense fallback={<div>Loading...</div>}>
        {/* 自定义图片-大屏预览 */}
        <ReactViewer
          images={viewImages}
          activeIndex={viewImagesIndex}
          onClose={() => {
            setViewImages([]);
            setViewImagesIndex(0);
          }}
        />
        {/* 图片编辑 */}
        <CompressImageEditor ref={imageEditorModalRef} />
        <ImageConfirmDialog ref={imageConfirmDialogRef} onOk={onOk} />
      </Suspense>
    </div>
  );
};

CompressImage.displayName = 'CompressImage';

export default CompressImage;
